import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { StyleSheet } from 'react-native';
import { useCopy } from 'common'; // TODO(Hadrien): Should we depend on common in a UC ?
import { BadgeIcon } from '../badge-icon';
import { Col } from '../../atoms/col';
import { TextBody } from '../../atoms/text';
export const SearchEmpty = memo(({ title, subtitle }) => {
    const { widgets: { assetSearchNoResult }, } = useCopy();
    return (_jsxs(Col.C, Object.assign({ p: "xl" }, { children: [_jsx(BadgeIcon, { name: "manage-search", size: "md" }), _jsx(TextBody, Object.assign({ mt: "xs", em: true, medium: true }, { children: title || assetSearchNoResult.title })), _jsx(TextBody, Object.assign({ mt: "xs", style: $.text }, { children: subtitle || assetSearchNoResult.subtitle }))] })));
});
const $ = StyleSheet.create({
    text: {
        textAlign: 'center',
    },
});
