var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback } from 'react';
import { TableCellAsset } from './table-cell-asset';
import { FIAT_HEADERS as HEADERS } from './market-table-header';
import { TableRow } from '../../molecules/table-row';
import { TableCell } from '../../molecules/table-cell';
import { AmountPrice } from '../../organisms/amount-price';
import { AmountChangePercent } from '../../organisms/amount-change-percent';
export const MarketTableItemFiat = memo((_a) => {
    var _b, _c, _d, _e;
    var { actions, currencyCode, data, onPress } = _a, rest = __rest(_a, ["actions", "currencyCode", "data", "onPress"]);
    // Handlers
    const handlePress = useCallback(() => {
        onPress === null || onPress === void 0 ? void 0 : onPress(currencyCode);
    }, [currencyCode, onPress]);
    const cellProps = {
        align: 'right',
        onPress: onPress ? handlePress : undefined,
    };
    // Render
    return (_jsxs(TableRow, { children: [_jsx(TableCellAsset, Object.assign({}, rest, { onPress: onPress ? handlePress : undefined, width: (_b = HEADERS[0]) === null || _b === void 0 ? void 0 : _b.width })), _jsx(TableCell, Object.assign({ width: (_c = HEADERS[1]) === null || _c === void 0 ? void 0 : _c.width }, cellProps, { children: _jsx(AmountPrice, { color: "onSurfaceEm", value: data.price, em: true }) })), _jsx(TableCell, Object.assign({ width: (_d = HEADERS[2]) === null || _d === void 0 ? void 0 : _d.width }, cellProps, { children: _jsx(AmountChangePercent, { value: data.change24hPercent, em: true }) })), _jsx(TableCell, Object.assign({ width: (_e = HEADERS[3]) === null || _e === void 0 ? void 0 : _e.width }, { children: actions }))] }));
});
