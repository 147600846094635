import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { Pressable } from 'react-native';
import { createStyles } from '../../theme';
import { TextBody } from '../text';
export function RadioBox({ active = false, label, onPress, title, value, }) {
    // Handlers
    const handlePress = useCallback(() => {
        onPress === null || onPress === void 0 ? void 0 : onPress(value);
    }, [onPress, value]);
    // Render
    return (_jsx(Pressable, Object.assign({ onPress: handlePress, style: ({ pressed }) => [$.container, (pressed || active) && $.active] }, { children: ({ pressed }) => {
            const texColor = pressed || active ? 'onPrimarySurface' : undefined;
            return (_jsxs(_Fragment, { children: [!!title && (_jsx(TextBody, Object.assign({ em: true, mb: "xxs", color: texColor }, { children: title }))), _jsx(TextBody, Object.assign({ color: texColor }, { children: label }))] }));
        } })));
}
const $ = createStyles({
    container: {
        borderRadius: 'rad1',
        backgroundColor: 'surfaceAlt',
        p: 'm',
    },
    active: {
        backgroundColor: 'primarySurface',
    },
});
