import { datadogRum } from '@datadog/browser-rum';
import { AnalyticsContext, BaseComponentProps, useSystemConfig } from 'common';
import { FC } from 'react';

export const AnalyticsContextProvider: FC<BaseComponentProps> = ({
  children,
}) => {
  /**
   * State
   */
  const { envName } = useSystemConfig();

  /**
   * DOM
   */
  return (
    <AnalyticsContext.Provider
      value={{
        getEnvironmentName: () => envName,
        identify: (userId: string, traits?: Record<string, unknown>) => {
          datadogRum.setUser({
            id: userId,
            ...traits,
          });
        },
      }}
    >
      <>{children}</>
    </AnalyticsContext.Provider>
  );
};
