var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { API } from 'api';
import { useMemo } from 'react';
import { MARKETS_HEADERS } from './constants';
import { TableRow } from '../../molecules/table-row';
import { TableCell } from '../../molecules/table-cell';
import { SortableHeader } from '../../molecules/sortable-header';
import { createStyles } from '../../theme';
const ACTIONS_HEADER = { label: '', width: '5%', isSortable: false };
export const BOND_HEADERS = [
    Object.assign(Object.assign({}, MARKETS_HEADERS.NAME), { width: '30%' }),
    Object.assign(Object.assign({}, MARKETS_HEADERS.YIELD), { width: '10%' }),
    Object.assign(Object.assign({}, MARKETS_HEADERS.COUPON), { width: '10%' }),
    Object.assign(Object.assign({}, MARKETS_HEADERS.PRICE), { width: '11%', isSortable: false }),
    Object.assign(Object.assign({}, MARKETS_HEADERS.MATURITY), { width: '12%' }),
    Object.assign(Object.assign({}, MARKETS_HEADERS.FREQUENCY), { width: '10%' }),
    Object.assign(Object.assign({}, MARKETS_HEADERS.ISSUANCE_AMOUNT), { width: '12%' }),
    ACTIONS_HEADER,
];
export const CRYPTO_HEADERS = [
    Object.assign(Object.assign({}, MARKETS_HEADERS.NAME), { width: '55%' }),
    Object.assign(Object.assign({}, MARKETS_HEADERS.PRICE), { width: '10%' }),
    Object.assign(Object.assign({}, MARKETS_HEADERS.PERCENTAGE_CHANGE), { width: '10%' }),
    Object.assign(Object.assign({}, MARKETS_HEADERS.VOLUME), { width: '10%' }),
    Object.assign(Object.assign({}, MARKETS_HEADERS.MARKET_CAP), { width: '10%' }),
    ACTIONS_HEADER,
];
export const FIAT_HEADERS = [
    Object.assign(Object.assign({}, MARKETS_HEADERS.NAME), { width: '75%', isSortable: false }),
    Object.assign(Object.assign({}, MARKETS_HEADERS.PRICE), { width: '10%', isSortable: false }),
    Object.assign(Object.assign({}, MARKETS_HEADERS.PERCENTAGE_CHANGE), { width: '10%', isSortable: false }),
    ACTIONS_HEADER,
];
export const FUND_HEADERS = [
    Object.assign(Object.assign({}, MARKETS_HEADERS.NAME), { width: '38%' }),
    Object.assign(Object.assign({}, MARKETS_HEADERS.CURRENCY), { width: '8%', isSortable: false }),
    Object.assign(Object.assign({}, MARKETS_HEADERS.ROR_MTD), { width: '15%', isSortable: false }),
    Object.assign(Object.assign({}, MARKETS_HEADERS.ROR_YTD), { width: '15%', isSortable: false }),
    Object.assign(Object.assign({}, MARKETS_HEADERS.TOTAL_RETURN), { width: '14%', isSortable: false }),
    Object.assign(Object.assign({}, MARKETS_HEADERS.MMD), { width: '10%', isSortable: false }),
];
export function MarketTableHeader(_a) {
    var { assetType } = _a, sortable = __rest(_a, ["assetType"]);
    // Vars
    const headers = useMemo(() => {
        if (assetType === API.MarketAssetType.Fiat) {
            return FIAT_HEADERS;
        }
        if (assetType === API.MarketAssetType.Fund) {
            return FUND_HEADERS;
        }
        if (assetType === API.MarketAssetType.Bond) {
            return BOND_HEADERS;
        }
        if (assetType === API.MarketAssetType.Crypto) {
            return CRYPTO_HEADERS;
        }
        return [];
    }, [assetType]);
    // Render
    return (_jsx(TableRow, Object.assign({ style: $.header }, { children: headers.map((_a, idx) => {
            var { isSortable = true, width } = _a, header = __rest(_a, ["isSortable", "width"]);
            return (_jsx(TableCell, Object.assign({ width: width, align: idx > 0 ? 'right' : 'left' }, { children: isSortable && !!header.id ? (_jsx(SortableHeader, Object.assign({}, header, sortable, { rtl: idx !== 0 }))) : (_jsx(SortableHeader, Object.assign({}, header))) }), header.label));
        }) })));
}
const $ = createStyles({
    header: {
        backgroundColor: 'surface',
        borderColor: 'outline',
    },
});
