import { createContext } from 'react';

export interface AnalyticsContextProps {
  getEnvironmentName: () => string;
  identify: (userId: string, traits?: Record<string, unknown>) => void;
}

export const AnalyticsContext = createContext<
  undefined | AnalyticsContextProps
>(undefined);
