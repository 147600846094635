import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { Pressable } from 'react-native';
import { createStyles } from '../../theme';
import { TextCaption } from '../../atoms/text';
export const PaginationPage = ({ active = false, value, onPress }) => {
    // Handlers
    const handlePress = useCallback(() => {
        onPress === null || onPress === void 0 ? void 0 : onPress(value);
    }, [onPress, value]);
    // Render
    return (_jsx(Pressable, Object.assign({ style: $.container, disabled: !onPress, onPress: handlePress }, { children: _jsx(TextCaption, Object.assign({ em: active }, { children: value })) })));
};
const $ = createStyles({
    container: {
        px: 'xxs',
    },
});
