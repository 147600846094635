import { action, Action } from 'easy-peasy';
import { FormErrors, FormValuesTouched } from '../utils/form';
import { BaseModel, createBaseModel } from './base-store';

interface FormModelState<Form extends object> {
  formInitialValues: Form;
  formValues: Form;
  formValuesTouched: FormValuesTouched<Form>;
  formErrors: FormErrors<Form>;
}

export interface FormModel<Form extends object>
  extends FormModelState<Form>,
    BaseModel {
  // actions
  resetForm: Action<FormModel<Form>, void>;
  setFormValues: Action<FormModel<Form>, Form | null>;
  setFormValueTouched: Action<FormModel<Form>, keyof Form>;
  setFormErrors: Action<FormModel<Form>, FormErrors<Form> | null>;
}

export function createFormModel<Form extends object>(
  initialValues: Form
): FormModel<Form> {
  return {
    ...createBaseModel(),

    // state
    formInitialValues: initialValues,
    formValues: initialValues,
    formValuesTouched: {},
    formErrors: {},

    // actions
    resetForm: action(state => {
      (state as unknown as FormModelState<Form>).formValues = initialValues;
      (state as unknown as FormModelState<Form>).formValuesTouched = {};
      (state as unknown as FormModelState<Form>).formErrors = {};
    }),

    setFormValues: action((state, payload) => {
      (state as unknown as FormModelState<Form>).formValues =
        payload ?? initialValues;
    }),
    setFormValueTouched: action((state, name) => {
      (state as unknown as FormModelState<Form>).formValuesTouched = {
        ...(state as unknown as FormModelState<Form>).formValuesTouched,
        [name]: true,
      };
    }),
    setFormErrors: action((state, payload) => {
      (state as unknown as FormModelState<Form>).formErrors = payload ?? {};
    }),
  };
}
