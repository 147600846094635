import { jsx as _jsx } from "react/jsx-runtime";
import { Pressable } from 'react-native';
import { useCallback } from 'react';
import { createStyles } from '../../theme';
export const PaginationButton = ({ disabled = false, icon, page, onPress, }) => {
    // Handlers
    const handlePress = useCallback(() => {
        onPress === null || onPress === void 0 ? void 0 : onPress(page);
    }, [onPress, page]);
    // Render
    return (_jsx(Pressable, Object.assign({ style: [$.container, disabled && $.disabled], disabled: disabled || !onPress, onPress: handlePress }, { children: icon })));
};
const $ = createStyles({
    container: {
        col: 5,
        p: 'xs',
        borderWidth: 1,
        borderColor: 'outline',
        borderRadius: 'rad1',
    },
    disabled: {
        opacity: 0.4,
    },
});
