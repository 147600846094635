import { API } from 'api';
import { action, Action, computed, Computed, thunk, Thunk } from 'easy-peasy';
import { SendDestinationType, SendForm } from '../../utils/formik/send/types';
import { getApiErrorCode } from '../../utils/get-api-error-code';
import { getApiErrorMessage } from '../../utils/get-api-error-message';
import { DataModel } from '../data-store';
import { AdditionalOptionsXHR, Injections, ThunkResult } from '../types';
import parseNumber from 'multi-number-parse';
import { createBalancesByAsset, factories } from '../../utils';
import { ApiError } from '../api-error';
import { API_HTTP_CODES, ERROR_MESSAGES } from '../../constants';
import { getAccountsByWorkflow } from '../../hooks';
import {
  Balances,
  Enriched,
  EnrichedAccountDetailAsset,
  EnrichedCryptoAddress,
} from '../../types';
import { createFormModel, FormModel } from '../form-store';

export interface SendModel extends FormModel<SendForm> {
  // state
  simulation:
    | Enriched.WithdrawalSimulation
    | Enriched.TransferSimulation
    | null;
  withdrawal: Enriched.Withdrawal | Enriched.Transfer | null;
  // computed
  asset: Computed<SendModel, EnrichedAccountDetailAsset | null, DataModel>;
  allowedAssets: Computed<SendModel, EnrichedAccountDetailAsset[], DataModel>;
  allowedCanSendAccounts: Computed<
    SendModel,
    Enriched.ListAccountItem[],
    DataModel
  >;
  assetBalances: Computed<SendModel, Balances | null>;
  destinationTypes: Computed<SendModel, SendDestinationType[], DataModel>;
  withdrawalAddresses: Computed<SendModel, EnrichedCryptoAddress[], DataModel>;
  withdrawalAddress: Computed<
    SendModel,
    EnrichedCryptoAddress | null,
    DataModel
  >;
  // actions
  _setSimulation: Action<
    SendModel,
    Enriched.WithdrawalSimulation | Enriched.TransferSimulation | null
  >;
  _setWithdrawal: Action<
    SendModel,
    Enriched.Withdrawal | Enriched.Transfer | null
  >;
  // thunk
  resetState: Thunk<SendModel, undefined, Injections, DataModel>;
  setSimulation: Thunk<
    SendModel,
    API.WithdrawalSimulation | API.TransferSimulation | null,
    Injections,
    DataModel
  >;
  setWithdrawal: Thunk<
    SendModel,
    API.Withdrawal | API.Transfer | null,
    Injections,
    DataModel
  >;
  simulate: Thunk<
    SendModel,
    void | AdditionalOptionsXHR,
    Injections,
    DataModel,
    Promise<
      | ThunkResult<API.WithdrawalSimulation | API.TransferSimulation | null>
      | undefined
    >
  >;
  create: Thunk<
    SendModel,
    void,
    Injections,
    DataModel,
    Promise<ThunkResult<API.Transfer | API.Withdrawal | null>>
  >;
  isConfirmedCode: boolean;
  setIsConfirmedCode: Action<SendModel, boolean>;
  confirmCode: Thunk<
    SendModel,
    string,
    Injections,
    DataModel,
    Promise<ThunkResult<void>>
  >;
  resendCode: Thunk<
    SendModel,
    undefined,
    Injections,
    DataModel,
    Promise<ThunkResult<void>>
  >;
  cancel: Thunk<SendModel, undefined, Injections, DataModel, Promise<boolean>>;
}

const initialFormValues: SendForm = {
  currencyCode: null,
  destinationType: SendDestinationType.crypto,
  destination: '',
  withdrawalAddressId: null,
  amount: null,
  description: null,
  network: null,
};

export const sendModel: SendModel = {
  ...createFormModel(initialFormValues),

  // state
  simulation: null,
  withdrawal: null,
  // computed
  asset: computed(
    [s => s.formValues.currencyCode, s => s.allowedAssets],
    (currencyCode, assets) => {
      if (!currencyCode) {
        return null;
      }

      const asset = assets.find(
        _asset => _asset.currency.code === currencyCode
      );

      return asset ?? null;
    }
  ),
  allowedAssets: computed(
    [(_state, storeState) => storeState.portfolio.accountDetail?.assets ?? []],
    assets => {
      return assets
        .filter(
          _asset =>
            _asset.canSendCrypto && !_asset.currency.hidden && _asset.hasBalance
        )
        .sort((a, b) => {
          return (
            Number(b.withdrawableQuantityUsd || 0) -
            Number(a.withdrawableQuantityUsd || 0)
          );
        });
    }
  ),
  allowedCanSendAccounts: computed(
    [
      (_state, storeState) => storeState.portfolio.accounts,
      (_state, storeState) => storeState.portfolio.assetHoldings?.accounts,
    ],
    (portfolioAccounts, assetAccounts) =>
      getAccountsByWorkflow({
        assetAccounts,
        portfolioAccounts,
        workflowType: 'workflow-send',
      })
  ),
  assetBalances: computed([s => s.asset], asset => {
    if (!asset) {
      return null;
    }

    return createBalancesByAsset(asset);
  }),
  destinationTypes: computed(
    [
      s => s.asset,
      s => s.withdrawalAddresses,
      (_state, storeState) => storeState.portfolio.accountDetail,
    ],
    (asset, withdrawalAddresses, accountDetail) => {
      const result = [SendDestinationType.crypto];

      if (
        !!accountDetail?.canStabletagTransfer ||
        !!asset?.canStabletagTransfer
      ) {
        result.push(SendDestinationType.transfer);
      }

      if (withdrawalAddresses.length > 0) {
        result.push(SendDestinationType.book);
      }

      return result;
    }
  ),
  withdrawalAddresses: computed(
    [
      s => s.asset,
      (_state, storeState) =>
        storeState.settings.cryptoAddresses.verifiedWithdrawalAddresses,
    ],
    (asset, addresses) => {
      if (!asset) {
        return [];
      }

      return addresses.filter(
        _address => _address.currencyCode === asset.currency.code
      );
    }
  ),
  withdrawalAddress: computed(
    [s => s.formValues.withdrawalAddressId, s => s.withdrawalAddresses],
    (addressId, addresses) => {
      if (!addressId) {
        return null;
      }

      const address = addresses.find(_address => _address.id === addressId);

      return address ?? null;
    }
  ),
  // actions
  resetState: thunk(actions => {
    actions.resetForm();

    actions.setError(null);
    actions.setSimulation(null);
    actions.setWithdrawal(null);
  }),
  _setSimulation: action((state, payload) => {
    state.simulation = payload;
  }),
  _setWithdrawal: action((state, payload) => {
    state.withdrawal = payload;
  }),
  // thunk
  setSimulation: thunk((actions, simulation, { getStoreState }) => {
    const storeState = getStoreState();

    const isWithdrawal =
      simulation &&
      typeof (simulation as API.TransferSimulation).destinationTag ===
        'undefined';

    actions._setSimulation(
      isWithdrawal
        ? factories.enrichWithdrawalSimulation(
            simulation as API.WithdrawalSimulation | null,
            storeState.metaData.currencies,
            storeState.metaData.fiatCurrencyCodes
          )
        : factories.enrichTransferSimulation(
            simulation as API.TransferSimulation | null,
            storeState.metaData.currencies,
            storeState.metaData.fiatCurrencyCodes
          )
    );
  }),
  setWithdrawal: thunk((actions, model, { getStoreState }) => {
    const storeState = getStoreState();

    const isWithdrawal =
      !!model && typeof (model as API.Transfer).destinationTag === 'undefined';

    actions._setWithdrawal(
      isWithdrawal
        ? factories.enrichWithdrawal(
            model as API.Withdrawal | null,
            storeState.metaData.currencies,
            storeState.metaData.fiatCurrencyCodes
          )
        : factories.enrichTransfer(
            model as API.Transfer | null,
            storeState.metaData.currencies,
            storeState.metaData.fiatCurrencyCodes
          )
    );
  }),
  simulate: thunk(
    async (actions, payload, { injections, getStoreState, getState }) => {
      const { isBackgroundXHR = false } = (payload ??
        {}) as AdditionalOptionsXHR;

      const state = getState();
      const storeState = getStoreState();

      // Important: Get latest form values since thunks are async
      const values = state.formValues;

      try {
        actions.setError(null);

        if (!isBackgroundXHR) {
          actions.setBusy(true);
        }

        injections.apiClient.setAdditionalHeaders({
          'x-account-id':
            storeState.portfolio.accountDetail?.account?.accountId || null,
        });

        let res:
          | API.TransferSimulationApiResponse
          | API.WithdrawalSimulationApiResponse;

        if (values.destinationType === SendDestinationType.transfer) {
          actions.setSimulation(null);

          res = await injections.apiClient.simulateTransfer({
            sourceAccountId: null,
            currencyCode: values.currencyCode as string,
            amount: values.amount as string,
            destinationTag: values.destination,
            description: values?.description || null,
            clientId: null,
            subtractFeeFromAmount: true,
            appAuthenticatorCode: null,
          });
        } else {
          res = await injections.apiClient.simulateWithdrawal({
            currencyCode: values.currencyCode as string,
            amount: values.amount as string,
            destinationAddress: values.destination,
            subtractFeeFromAmount: true,
            description: values?.description || null,
            withdrawalAddressId: values.withdrawalAddressId,
            appAuthenticatorCode: null,
          });
        }

        if (res.result) {
          actions.setSimulation(res.result);
        }

        if (!isBackgroundXHR) {
          actions.setBusy(false);
        }

        if (!res.isSuccessful || !res.result) {
          actions.setError(res.errorMessage);
          actions.setSimulation(null);

          // Error
          return {
            isSuccessful: false,
            errorMessage: res.errorMessage,
          };
        }

        // Success
        return {
          isSuccessful: true,
          result: res.result,
        };
      } catch (error) {
        const errorCode = getApiErrorCode(error);
        const message = getApiErrorMessage(error);
        if (!isBackgroundXHR) {
          actions.setBusy(false);
        }
        actions.setError(message);

        // Error
        return {
          isSuccessful: false,
          errorMessage: message,
          errorCode,
        };
      }
    }
  ),
  create: thunk(
    async (actions, _void, { injections, getState, getStoreState }) => {
      const state = getState();
      const storeState = getStoreState();

      // Important: Get latest form values since thunks are async
      const values = state.formValues;

      try {
        actions.setBusy(true);
        actions.setError(null);

        const amount = values.amount;
        const description = values.description ?? null;

        if (!amount || !values.currencyCode) {
          throw new ApiError(
            API_HTTP_CODES.UNPROCESSABLE_ENTITY,
            ERROR_MESSAGES.UNPROCESSABLE_ENTITY,
            -1
          );
        }

        injections.apiClient.setAdditionalHeaders({
          'x-account-id':
            storeState.portfolio.accountDetail?.account?.accountId || null,
        });

        const balance = parseNumber(state.asset?.quantity || '0');
        const isMax = parseNumber(amount) >= balance;

        const req = {
          amount,
          clientId: null,
          currencyCode: values.currencyCode,
          description,
          googleAuthenticatorCode: null, // deprecated (Auth0)
          appAuthenticatorCode: null, // deprecated (Auth0)
        };

        let res: API.TransferApiResponse | API.WithdrawalApiResponse | null =
          null;

        const isTransfer =
          values.destinationType === SendDestinationType.transfer;

        if (isTransfer) {
          res = await injections.apiClient.createTransfer({
            ...req,
            sourceAccountId: null,
            destinationTag: values.destination,
            subtractFeeFromAmount: isMax,
          });
        } else {
          res = await injections.apiClient.createWithdrawal({
            ...req,
            destinationAddress: values.destination,
            withdrawalAddressId: values.withdrawalAddressId,
            subtractFeeFromAmount: true,
          });
        }

        if (res?.isSuccessful) {
          actions.setWithdrawal(res.result);
        } else {
          actions.setError(res.errorMessage);
        }

        return res;
      } catch (error) {
        const errorCode = getApiErrorCode(error);
        const message = getApiErrorMessage(error);

        actions.setError(message);

        return {
          isSuccessful: false,
          errorMessage: message,
          errorCode,
        };
      } finally {
        actions.setBusy(false);
      }
    }
  ),
  isConfirmedCode: false,
  setIsConfirmedCode: action((state, payload) => {
    state.isConfirmedCode = payload;
  }),
  confirmCode: thunk(
    async (actions, payload, { injections, getState, getStoreState }) => {
      const storeState = getStoreState();
      try {
        actions.setBusy(true);
        actions.setError(null);
        const state = getState();

        if (!state.withdrawal) {
          throw Error('Withdrawal has not been set');
        }

        const id = state.withdrawal.id;
        const isTransfer = !!(state.withdrawal as API.Transfer).destinationTag;
        const params = isTransfer
          ? {
              id: id,
              code: payload,
            }
          : {
              withdrawalId: id,
              code: payload,
            };

        injections.apiClient.setAdditionalHeaders({
          'x-account-id':
            storeState.portfolio.accountDetail?.account?.accountId,
        });

        const { isSuccessful, errorMessage, errorCode } =
          await injections.apiClient[
            isTransfer ? 'confirmTransferCode' : 'confirmWithdrawalCode'
          ](params as unknown as any);

        if (!isSuccessful) {
          actions.setIsConfirmedCode(false);
          actions.setError(errorMessage);
          actions.setBusy(false);

          return {
            isSuccessful: false,
            errorMessage,
            errorCode,
          };
        }

        actions.setIsConfirmedCode(true);
        actions.setBusy(false);
        return {
          isSuccessful: true,
        };
      } catch (error) {
        actions.setIsConfirmedCode(false);

        const errorMessage = getApiErrorMessage(error);
        const errorCode = getApiErrorCode(error);
        actions.setError(errorMessage);
        actions.setBusy(false);
        return {
          isSuccessful: false,
          errorMessage,
          errorCode,
        };
      }
    }
  ),
  resendCode: thunk(
    async (actions, _request, { injections, getState, getStoreState }) => {
      const storeState = getStoreState();
      try {
        actions.setBusy(true);
        actions.setError(null);
        const state = getState();
        if (!state.withdrawal) {
          throw Error('Withdrawal has not been set');
        }
        const id = state.withdrawal.id;
        const isTransfer = !!(state.withdrawal as API.Transfer).destinationTag;
        const params = isTransfer
          ? {
              transferId: id,
            }
          : {
              withdrawalId: id,
            };

        injections.apiClient.setAdditionalHeaders({
          'x-account-id':
            storeState.portfolio.accountDetail?.account?.accountId,
        });

        const response = await injections.apiClient[
          isTransfer
            ? 'resendTransferVerificationCode'
            : 'resendWithdrawalVerificationCode'
        ](params as unknown as any);
        actions.setBusy(false);
        return response;
      } catch (error) {
        const errorMessage = getApiErrorMessage(error);
        actions.setError(errorMessage);
        actions.setBusy(false);
        return {
          isSuccessful: false,
          errorMessage,
        };
      }
    }
  ),
  cancel: thunk(
    async (actions, _request, { injections, getState, getStoreState }) => {
      const storeState = getStoreState();

      try {
        actions.setBusy(true);
        actions.setError(null);

        const state = getState();

        if (!state.withdrawal) throw Error('Withdrawal has not been set');

        const id = state.withdrawal.id;
        const isTransfer = !!(state.withdrawal as API.Transfer).destinationTag;

        let result: API.ApiResponse;

        injections.apiClient.setAdditionalHeaders({
          'x-account-id':
            storeState.portfolio.accountDetail?.account?.accountId,
        });

        if (isTransfer) {
          result = await injections.apiClient.cancelTransfer({
            transferId: id,
          });
        } else {
          result = await injections.apiClient.cancelWithdrawal({
            withdrawalId: id,
          });
        }

        actions.setBusy(false);

        return result.isSuccessful;
      } catch (error) {
        actions.setError(getApiErrorMessage(error));
        actions.setBusy(false);

        return false;
      }
    }
  ),
};
