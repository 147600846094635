import { action, Action, thunk, Thunk } from 'easy-peasy';
import { API } from 'api';
import { Injections, AdditionalOptionsXHR } from './types';
import { DataModel } from './data-store';
import { runThunk } from '../utils/run-thunk';
import { runThunkUnmanaged } from '../utils/run-thunk-unmanaged';
import { removeImpersonation } from '../utils/remove-header';

type PendingOperationsPayload = {
  request: API.PendingOperationsRequest;
} & AdditionalOptionsXHR;

type OperationDetailPayload = {
  request: API.OperationDetailRequest;
} & AdditionalOptionsXHR;

export interface QuorumModel {
  // state
  pendingOperations: API.QuorumOperationArrayPaginatedApiResponse | null;
  operationDetails: API.QuorumOperation | null;
  // actions
  setPendingOperations: Action<
    QuorumModel,
    API.QuorumOperationArrayPaginatedApiResponse
  >;
  setOperationDetails: Action<QuorumModel, API.QuorumOperation | null>;
  // thunk
  getPendingOperations: Thunk<
    QuorumModel,
    PendingOperationsPayload,
    Injections,
    DataModel,
    Promise<void>
  >;
  getOperationDetails: Thunk<
    QuorumModel,
    OperationDetailPayload,
    Injections,
    DataModel
  >;
  approveOperation: Thunk<
    QuorumModel,
    API.ApproveOperationRequest,
    Injections,
    DataModel,
    Promise<boolean>
  >;
  cancelOperation: Thunk<
    QuorumModel,
    API.CancelOperationRequest,
    Injections,
    DataModel,
    Promise<boolean>
  >;
}

export const quorumModel: QuorumModel = {
  // state
  pendingOperations: null,
  operationDetails: null,
  // actions
  setPendingOperations: action((state, payload) => {
    state.pendingOperations = payload;
  }),
  setOperationDetails: action((state, payload) => {
    state.operationDetails = payload;
  }),
  // thunk
  getPendingOperations: thunk(async (actions, payload, helpers) => {
    const headers = removeImpersonation(
      helpers.getStoreState().additionalHeaders
    );
    await runThunk<API.QuorumOperationArrayPaginatedApiResponse>(
      helpers,
      {
        execute: async () => {
          const result =
            await helpers.injections.apiClient.getPendingOperations(
              payload.request
            );

          return result;
        },
        onSucccess: response => {
          // @ts-ignore TODO(@jey) - fix typings
          actions.setPendingOperations(response || []);
        },
        onError: message => {
          if (payload.throwOnError) {
            throw new Error('Error on getPendingOperations: ' + message);
          }
        },
      },
      !payload.isBackgroundXHR,
      true,
      headers
    );
  }),
  getOperationDetails: thunk(async (actions, payload, helpers) => {
    const headers = removeImpersonation(
      helpers.getStoreState().additionalHeaders
    );
    await runThunk<API.QuorumOperationApiResponse>(
      helpers,
      {
        execute: async () =>
          await helpers.injections.apiClient.getOperationDetails(
            payload.request
          ),
        onSucccess: response => {
          actions.setOperationDetails(response.result);
        },
        onError: message => {
          if (payload.throwOnError) {
            throw new Error('Error on getOperationDetails: ' + message);
          }
        },
      },
      true,
      true,
      headers
    );
  }),
  approveOperation: thunk(async (_actions, payload, helpers) => {
    const headers = removeImpersonation(
      helpers.getStoreState().additionalHeaders
    );
    let isSuccessful = false;
    await runThunkUnmanaged(
      helpers,
      {
        execute: async () => {
          const result =
            await helpers.injections.apiClient.approveOperation(payload);
          isSuccessful = result.isSuccessful;
          return { ...result };
        },
      },
      true,
      true,
      headers
    );
    return isSuccessful;
  }),
  cancelOperation: thunk(async (_actions, payload, helpers) => {
    const headers = removeImpersonation(
      helpers.getStoreState().additionalHeaders
    );
    let isSuccessful = false;
    await runThunkUnmanaged(
      helpers,
      {
        execute: async () => {
          const result =
            await helpers.injections.apiClient.cancelOperation(payload);
          isSuccessful = result.isSuccessful;
          return { ...result };
        },
      },
      true,
      true,
      headers
    );
    return isSuccessful;
  }),
};
