import React, { FC } from 'react';
import _noop from 'lodash/noop';
import { AppSelector, SelectorProps } from '../app-selector';
import cx from 'classnames';
import { getSelectInputStyle } from '../form/helpers';

export type SelectInputProps = Omit<SelectorProps, 'onChange'> & {
  asAddon?: boolean;
  isActive?: boolean;
  errored?: boolean;
  onChange?: (newValue: any) => void;
};

export const SelectInput: FC<SelectInputProps> = ({
  cls,
  errored = false,
  asAddon = false,
  isActive = false,
  ...rest
}) => {
  const className = getSelectInputStyle(errored, asAddon, isActive);

  return (
    <AppSelector
      onChange={_noop}
      {...rest}
      cls={cx(className, cls)}
      asAddon={asAddon}
    />
  );
};
