import cx from 'classnames';
import { AppLoader } from '../app-loader';
import { Cell, Props as CellProps } from './cell';
import { array } from 'is';
import { isValidElement } from 'react';
import { Link } from 'react-router-dom';

interface BaseProps<C, R> {
  as?: 'div' | typeof Link;
  columns: CellProps<C, R>[];
  cls?: string;
  cellItemCls?: string;
  rowPadding?: string;
  rows: R[] | null | undefined;
  containerCls?: string;
  onRowClick?: (value: R) => void;
  borderBottomColor?: string;
  cellRowCls?: string;
  textCls?: string;
  emptyDataText?: string | JSX.Element;
  placeholderCellText?: string;
}

type DivProps<C, R> = BaseProps<C, R> & React.ComponentPropsWithoutRef<'div'>;

type AnchorProps<C, R> = BaseProps<C, R> &
  React.ComponentPropsWithoutRef<typeof Link> & {
    to: (row: R) => string | null;
  };

type Props<C, R> = DivProps<C, R> | AnchorProps<C, R>;

export const AppTableRows = <C, R>({
  as = 'div',
  columns,
  rows,
  onRowClick,
  cls = 'cursor-pointer hover-bg-grey-brighter',
  cellItemCls,
  emptyDataText = 'No Data',
  cellRowCls = 'justify-start',
  textCls = 'text-primary text-sm',
  rowPadding = 'px-4 md:px-6',
  containerCls = 'rounded-b-4 border-1 grey-bright',
  borderBottomColor = 'grey-bright',
  placeholderCellText = '-',
  ...rest
}: Props<C, R>) => {
  /**
   * DOM
   */
  if (!rows) {
    return (
      <div className="relative my-24">
        <AppLoader
          isFixed={false}
          bgColor="-top-24 bg-white border border-t-0 rounded-b"
        />
      </div>
    );
  }
  if (array(rows) && !rows?.length) {
    return (
      <div className="bg-white border grey-bright rounded-b">
        {isValidElement(emptyDataText) ? (
          emptyDataText
        ) : (
          <p className="text-grey-darker text-sm p-10 flex items-center justify-center">
            {emptyDataText}
          </p>
        )}
      </div>
    );
  }

  return (
    <div className={cx('bg-white', containerCls)}>
      {rows?.map((row, index) => {
        const cells = columns.map(col => {
          return (
            <Cell<C, R>
              cls={cellItemCls}
              {...col}
              key={JSON.stringify(col.name)}
              row={row}
              type="row"
              placeholder={placeholderCellText}
            />
          );
        });
        const isLastItem = index === rows.length - 1;
        const to = (rest as AnchorProps<C, R>).to?.(row);
        const Component = as && !!to ? as : 'div';
        const toStr = as === Link ? (rest as AnchorProps<C, R>).to(row) : '';

        return (
          <Component
            key={JSON.stringify(row)}
            to={toStr!}
            className={cx('block py-3 select-text', rowPadding, cls, {
              [`${borderBottomColor} border-b`]: !isLastItem,
              'rounded-b': isLastItem,
            })}
            onClick={e => {
              const selection = window.getSelection()?.toString();
              if (selection) {
                e.preventDefault();
                e.stopPropagation();
                return;
              }
              if (!onRowClick) {
                return;
              }
              onRowClick(row);
            }}
            onDragStart={e => {
              e.preventDefault();
            }}
          >
            <div
              className={cx(
                cellRowCls,
                textCls,
                'flex flex-row items-center justify-between'
              )}
            >
              {cells}
            </div>
          </Component>
        );
      })}
    </div>
  );
};
