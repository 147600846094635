var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { _MarketListItem } from './_market-list-item';
import { AmountRoR } from '../../organisms/amount-ror';
import { Col } from '../../atoms/col';
import { createStyles } from '../../theme';
export const MarketListItemFund = memo((_a) => {
    var { data, description, displayCode } = _a, rest = __rest(_a, ["data", "description", "displayCode"]);
    return (_jsx(_MarketListItem, Object.assign({}, rest, { displayCode: displayCode, subtitle: description, coinIcon: null, addon: _jsxs(Col.R, { children: [_jsx(AmountRoR, { type: "mtd", value: data.mtdRoR }), _jsx(Col, Object.assign({ style: $.space }, { children: _jsx(AmountRoR, { type: "ytd", value: data.ytdRoR }) }))] }) })));
});
const $ = createStyles({
    space: {
        marginTop: 2,
    },
});
