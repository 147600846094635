import { AppDialog, useEnvironmentConfig } from 'common';
import { useState } from 'react';
import Hotkeys from 'react-hot-keys';
import { AppLogo } from '~/components/app-logo';
import { DevTools } from '~/components/devtools';
import { IS_LOCAL_DEV } from '~/utils/is-local-dev';

export const AppDebugDialogs = ({
  isAuthenticated,
}: {
  isAuthenticated: boolean;
}) => {
  /**
   * Vars
   */
  const {
    REACT_APP_SHA = '',
    REACT_APP_BUILD_ENV = '',
    REACT_APP_SHA_TIMESTAMP = '',
  } = useEnvironmentConfig();
  const HOT_KEYS_MAP = {
    VERSION: `ctrl+shift+a`,
    DEVTOOLS: `ctrl+shift+d`,
  };
  const appVersion = `${REACT_APP_BUILD_ENV} ${REACT_APP_SHA} ${REACT_APP_SHA_TIMESTAMP ? '| ' + REACT_APP_SHA_TIMESTAMP : ''}`;
  /**
   * State
   */
  const [showAboutApp, setShowAboutApp] = useState(false);
  const [showFeatureFlagsDialog, setShowFeatureFlagsDialog] = useState(false);

  /**
   * DOM
   */
  return (
    <>
      <Hotkeys
        keyName={`${HOT_KEYS_MAP.VERSION},${HOT_KEYS_MAP.DEVTOOLS}`}
        onKeyUp={keyName => {
          if (keyName === HOT_KEYS_MAP.VERSION) {
            setShowAboutApp(true);
            return;
          }
          if (keyName === HOT_KEYS_MAP.DEVTOOLS) {
            if (!isAuthenticated) {
              return;
            }
            if (!IS_LOCAL_DEV) {
              return null;
            }
            setShowFeatureFlagsDialog(true);
            return;
          }
        }}
      />
      {/* dialogs: app version */}
      <AppDialog
        widthCls="max-w-sm"
        isOpen={showAboutApp}
        onClose={() => setShowAboutApp(false)}
      >
        <div className="flex flex-col items-center p-10">
          <AppLogo variant="dark" cls="pt-4" />
          <p className="p-4 pointer-none text-xs font-light text-right">
            App Version: <b>{appVersion}</b>
          </p>
        </div>
      </AppDialog>
      {/* dialogs: debug/ devtools */}
      <AppDialog
        isOpen={showFeatureFlagsDialog}
        onClose={() => setShowFeatureFlagsDialog(!showFeatureFlagsDialog)}
      >
        <DevTools />
      </AppDialog>
    </>
  );
};
