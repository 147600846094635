import { FC, useEffect } from 'react';
import { APP_ROUTES } from '../../routes';
import { appStorage } from '../../utils/app-storage';
import { OpenLayout } from '../layouts/open-layout';
import { FallbackCta } from '~/components/404/fallback-cta';

export const Unrestricted429: FC = () => {
  useEffect(() => {
    appStorage.jwt.remove();
  }, []);

  return (
    <OpenLayout
      icon="flag-outline"
      title="Oops... Too many requests"
      cta={
        <FallbackCta
          href={APP_ROUTES.NON_AUTH_HOME}
          label="Go to sign in page"
        />
      }
      withAppWrapper
    >
      You have made too many requests. Please wait a moment and try again
    </OpenLayout>
  );
};
