import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { PaginationPage } from '../../molecules/pagination-page';
import { TextCaption } from '../../atoms/text';
import { Row } from '../../atoms/row';
import { PaginationButton } from '../../molecules/pagination-button';
import { Icon } from '../../molecules/icon';
export function Pagination({ currentPage = 0, totalPages = 0, onPressPage, }) {
    const renderPageNumbers = useMemo(() => {
        const pageNumbers = [];
        let start = Math.max(1, currentPage - 2);
        let end = Math.min(totalPages, currentPage + 2);
        // Adjust start and end when there's a single ellipsis
        if (start === 2)
            start = 1; // Ensure at least three pages before the ellipsis
        if (end === totalPages - 1)
            end = totalPages; // Ensure at least three pages after the ellipsis
        if (start > 1) {
            pageNumbers.push(_jsx(PaginationPage, { onPress: onPressPage, value: 1, active: currentPage === 1 }, "page-1"));
            if (start > 2) {
                pageNumbers.push(_jsx(TextCaption, { children: "..." }, "ellipsis-start"));
            }
        }
        for (let i = start; i <= end; i++) {
            pageNumbers.push(_jsx(PaginationPage, { onPress: onPressPage, value: i, active: currentPage === i }, `page-${i}`));
        }
        if (end < totalPages - 1) {
            pageNumbers.push(_jsx(TextCaption, { children: "..." }, "ellipsis-end"));
        }
        if (end < totalPages) {
            pageNumbers.push(_jsx(PaginationPage, { onPress: onPressPage, value: totalPages, active: currentPage === totalPages }, `page-${totalPages}`));
        }
        return pageNumbers;
    }, [currentPage, onPressPage, totalPages]);
    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === totalPages;
    if (!currentPage)
        return null;
    if (totalPages <= 1)
        return null;
    return (_jsxs(Row.LR, Object.assign({ p: "m" }, { children: [_jsx(PaginationButton, { page: currentPage - 1, onPress: onPressPage, disabled: isFirstPage, icon: _jsx(Icon, { name: "chevron-left", size: "sm" }) }), _jsx(Row.C, { children: renderPageNumbers }), _jsx(PaginationButton, { page: currentPage + 1, onPress: onPressPage, disabled: isLastPage, icon: _jsx(Icon, { name: "chevron-right", size: "sm" }) })] })));
}
