import { memo } from 'react';

type Props = {
  addon?: JSX.Element;
  children: string;
};

export const FormLabel = memo(({ addon, children }: Props) => {
  return (
    <div className="flex flex-row items-center justify-between mb-1">
      <span className="text-sm text-grey-darker">{children}</span>
      {addon}
    </div>
  );
});
