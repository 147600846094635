import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { createStyles } from '../../theme';
import { Col } from '../../atoms/col';
import { TextBody } from '../../atoms/text';
export const LabeledValue = memo(({ label, value }) => {
    return (_jsxs(Col.L, Object.assign({ style: $.container }, { children: [_jsx(TextBody, { children: label }), _jsx(Col, Object.assign({ style: $.space }, { children: typeof value === 'string' ? _jsx(TextBody, Object.assign({ em: true }, { children: value })) : value }))] })));
});
const $ = createStyles({
    container: {
        flex: 1,
    },
    space: {
        marginTop: 2,
    },
});
