import { FC } from 'react';
import { DataStore } from '../../../store';
import { SendDestinationType } from 'common';
import { StepWizardChildProps } from 'react-step-wizard';
import { AddCryptoAddressFlow } from '../add-crypto-address';

export const AddCryptoAddress: FC<Partial<StepWizardChildProps>> = ({
  goToNamedStep,
}) => {
  if (!goToNamedStep) {
    return null;
  }
  /**
   * Store
   */

  const allowedCurrencies = DataStore.useStoreState(
    s => s.settings.cryptoAddresses.allowedCurrencies
  );
  const currencyCode = DataStore.useStoreState(
    s => s.send.formValues?.currencyCode
  );
  const withdrawalAddresses = DataStore.useStoreState(
    s => s.settings.cryptoAddresses.withdrawalAddresses
  );
  const currentAddress = DataStore.useStoreState(
    s => s.settings.cryptoAddresses.currentAddress
  );
  const formValues = DataStore.useStoreState(s => s.send.formValues);
  const setFormValues = DataStore.useStoreActions(s => s.send.setFormValues);

  /**
   * DOM
   */
  return (
    <AddCryptoAddressFlow
      onClose={() => {
        goToNamedStep('send-form');
      }}
      showIntro={!!withdrawalAddresses?.length}
      onAddressAdded={() => {
        if (!currencyCode) {
          return goToNamedStep('send-form');
        }
        const selectedCurrency = allowedCurrencies?.find(
          ({ code }) => code === currencyCode
        );
        if (!selectedCurrency) return;

        setFormValues({
          ...formValues,
          network: selectedCurrency.network,
          destinationType: SendDestinationType.book,
          destination: currentAddress?.address || '',
        });
        goToNamedStep('send-form');
      }}
      currencyCode={currencyCode}
    />
  );
};
