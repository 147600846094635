import { action, Action } from 'easy-peasy';
export interface _PausableModel {
  paused: boolean;
  setPaused: Action<_PausableModel, boolean>;
}
export function _extendPausableModel(): _PausableModel {
  return {
    paused: false,
    setPaused: action((state, payload) => {
      state.paused = payload;
    }),
  };
}
