import { ReactComponent as ReferredIcon } from 'common/dist/assets/icons/referred.svg';
import { DataStore } from '../../store';
import { getStorageKeys, useEnvironmentConfig } from 'common';
import { DASHBOARD_SELECTED_DIALOG_TYPE } from '~/store/types';

interface Props {
  onActionClick: (action: DASHBOARD_SELECTED_DIALOG_TYPE | null) => void;
}

export const ReferredDialog: React.FC<Props> = ({ onActionClick }) => {
  /**
   * Store
   */
  const identity = DataStore.useStoreState(s => s.user.identity);
  const isKycVerified = DataStore.useStoreState(s => s.user.isKycVerified);
  const allowDeposits = DataStore.useStoreState(
    s => !!s.portfolio.accountDetail?.settings?.allowDeposits
  );

  /**
   * Vars
   */
  const { REACT_APP_BUILD_ENV_NAME } = useEnvironmentConfig();
  const { confirmReferredModal } = getStorageKeys(
    'web',
    REACT_APP_BUILD_ENV_NAME
  );

  /**
   * DOM
   */
  if (!identity) {
    return null;
  }
  return (
    <div className="flex flex-col align-middle items-center p-10 pb-5">
      <ReferredIcon />
      <>
        <div className="text-2xl font-bold mt-5 text-center">
          Welcome! You have been referred by
        </div>
        <div className="text-2xl font-bold text-center">
          {identity?.referredFirstname}
        </div>
        <div className="text-gray-400 mt-6 text-center overflow-y-auto max-h-56 mb-10">
          To receive your referral bonus, simply make a deposit of $200 or more
          and hold it in your Stablehouse account for more than 60 days.
        </div>
        {!allowDeposits ? (
          <button
            className="app-button-accent mb-5"
            onClick={e => {
              e.preventDefault();
              localStorage.setItem(confirmReferredModal, 'CONFIRMED');
              onActionClick(null);
            }}
          >
            Ok
          </button>
        ) : (
          <button
            className="app-button-accent mb-5"
            onClick={e => {
              e.preventDefault();
              localStorage.setItem(confirmReferredModal, 'CONFIRMED');
              if (!isKycVerified) {
                onActionClick('KYC');
                return;
              }
              onActionClick('workflow-add-cash');
            }}
          >
            Make a deposit
          </button>
        )}
      </>
    </div>
  );
};
