import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../routes';
import { useEffect } from 'react';
import { appStorage } from '../../utils/app-storage';
import { OpenLayout } from '../layouts/open-layout';
import { DataStore } from '~/store';

export const AccountDisabled: FC = () => {
  /**
   * Store
   */
  const setError = DataStore.useStoreActions(_ => _.setError);

  /**
   * Hooks
   */
  useEffect(() => {
    appStorage.jwt.remove();
  }, []);
  const navigate = useNavigate();

  /**
   * Methods
   */
  const handleGoToSignInPage = useCallback(() => {
    setError(null);
    navigate(APP_ROUTES.NON_AUTH_HOME);
  }, []);

  /**
   * DOM
   */
  return (
    <OpenLayout
      icon="negative-circle-shield"
      title="Your account has been disabled."
      cta={
        <button
          role="button"
          className="app-button-primary mt-12"
          style={{ width: 275 }}
          onClick={handleGoToSignInPage}
        >
          Go to sign in
        </button>
      }
      withAppWrapper
    >
      <>
        Please contact support at{' '}
        <a href="mailto:support@stablehouse.com">
          <strong>support@stablehouse.com</strong>
        </a>{' '}
        for next steps and/or to withdraw your funds.
      </>
    </OpenLayout>
  );
};
