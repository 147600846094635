import { FC, useCallback } from 'react';
import { DataStore } from '~/store';
import { Menu } from '@headlessui/react';
import { AppContainer, AppIcon } from 'common';
import { useNavigate, useParams } from 'react-router-dom';
import { APP_ROUTES } from '~/routes';
import cx from 'classnames';
import { getAccountType } from '~/utils/get-account-details';
import { AccountTag } from '@xbto/universal-components';

type Props = {
  title?: string | undefined;
  onAccountSelected?: (arg: string) => void;
  customBasePath?: string;
};

export const BreadCrumbsNavigation: FC<Props> = ({
  title,
  onAccountSelected,
  customBasePath = APP_ROUTES.AUTH_PORTFOLIO,
}) => {
  /**
   * Store
   */
  const accounts = DataStore.useStoreState(s => s.portfolio.accounts);
  const accountDetail = DataStore.useStoreState(s => s.portfolio.accountDetail);
  const accountsByType = DataStore.useStoreState(
    s => s.portfolio.accountsByType
  );
  const clientUserType = DataStore.useStoreState(s => s.user.clientUserType);

  /**
   * Hooks
   */
  const navigate = useNavigate();
  const { fundAccountId } = useParams();

  /**
   * Methods
   */
  const handleOnPortfolioClicked = useCallback(() => {
    if (clientUserType === 'admin') {
      return;
    }
    navigate(APP_ROUTES.AUTH_PORTFOLIO);
  }, [navigate]);
  const handleOnAccountChanged = useCallback(
    async accId => {
      const accountId =
        clientUserType === 'admin'
          ? fundAccountId
          : accountDetail?.account?.accountId;
      if (accId === accountId) {
        // do nothing for already selected account
        return;
      }
      if (onAccountSelected) {
        onAccountSelected(accId);
      }
      navigate(customBasePath + `/${accId}`, {
        replace: true,
        relative: 'path',
        preventScrollReset: !!customBasePath,
      });
    },
    [navigate, customBasePath, fundAccountId, accountDetail?.account?.accountId]
  );

  /**
   * DOM
   */
  if (!accountsByType) {
    return null;
  }
  const hasAccounts = !!(accounts.length > 0);
  return (
    <header className={'py-4 border-b'}>
      <AppContainer bgColor cls="px-6 py-0" containerWidth="lg">
        <div className={'flex text-primary gap-x-3.5'}>
          {!!title && (
            <span className="flex flex-row items-center gap-x-3">
              <span
                role="button"
                onClick={handleOnPortfolioClicked}
                className={
                  'text-grey-darker text-sm font-semibold hover:underline'
                }
              >
                {title}
              </span>
              <AppIcon
                size={14}
                cls="mt-1"
                icon="chevron-grey-right"
                bg="bg-transparent"
              />
            </span>
          )}
          {hasAccounts && (
            <div className="relative">
              <Menu>
                {({ open }) => (
                  <>
                    <Menu.Button
                      className={
                        'flex items-center font-semibold justify-center gap-x-1'
                      }
                    >
                      {!!accountDetail && (
                        <span
                          className={
                            'text-sm flex flex-row gap-x-1.5 font-semibold'
                          }
                        >
                          <span>{accountDetail.account?.label}</span>
                          <AccountTag
                            value={getAccountType(accountDetail.account)}
                            closed={accountDetail.account?.closed}
                          />
                        </span>
                      )}
                      <AppIcon
                        size={24}
                        cls={cx(
                          'inline-block items-center justify-center svg-stretch-w-h transform duration-100',
                          {
                            'rotate-180': open,
                          }
                        )}
                        icon="chevron-icon-drop-down"
                        fill="fill-current-color-dark"
                        bg="bg-transparent"
                      />
                    </Menu.Button>
                    <Menu.Items className="absolute max-w-xs divide-y mt-0 origin-top-left bg-white shadow-lg z-10 rounded border">
                      {accountsByType.map(([accType, accounts]) => {
                        return (
                          <span key={accType}>
                            {accounts.map(acc => {
                              return (
                                <Menu.Item
                                  key={`${accType}-${acc.account?.accountId}`}
                                >
                                  {({ active }) => (
                                    <div
                                      className={`flex flex-row justify-between items-center p-4 border-b cursor-pointer gap-6 overflow-hidden ${
                                        active && 'bg-gray-100'
                                      } `}
                                      onClick={e => {
                                        e.preventDefault();
                                        if (!acc.account?.accountId) {
                                          return;
                                        }
                                        handleOnAccountChanged(
                                          acc.account?.accountId
                                        );
                                      }}
                                    >
                                      <div className="flex flex-col">
                                        <span className="truncate flex-1 text-sm font-semibold">
                                          {acc.account?.label}
                                        </span>
                                        <span className="truncate text-xs text-grey-darker">
                                          {acc.formatted.maskedAccountNumber}
                                        </span>
                                      </div>
                                      <AccountTag
                                        value={getAccountType(acc?.account)}
                                        closed={acc?.account?.closed}
                                      />
                                    </div>
                                  )}
                                </Menu.Item>
                              );
                            })}
                          </span>
                        );
                      })}
                    </Menu.Items>
                  </>
                )}
              </Menu>
            </div>
          )}
        </div>
      </AppContainer>
    </header>
  );
};
