import { FC, useEffect, useState } from 'react';
import { DataStore } from '../store';
import { AppLoader } from '../components/app-loader';
import { useBrowserDatadog } from '../hooks/use-datadog';
import { appStorage } from '../utils/app-storage';
import { ApiError, BaseComponentProps, useComputedConfig } from 'common';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../routes';
import { useLocalStorageUpdates } from '../hooks/use-localstorage-updates';
import { useLogout } from '~/hooks/use-authentication-hooks';
import { handleNavigation } from '~/components/shared/url-params/url-params';

export const AppStartup: FC<BaseComponentProps> = props => {
  /**
   * Store
   */
  const booted = DataStore.useStoreState(s => s.booted);
  const identity = DataStore.useStoreState(s => s.user.identity);
  const error = DataStore.useStoreState(s => s.error);
  const isAuthenticated = DataStore.useStoreState(s => s.user.isAuthenticated);
  const isEmailVerified = DataStore.useStoreState(
    s => !!s.user.isEmailVerified
  );

  const { init, _decodeIdentity, resetUserStore } = DataStore.useStoreActions(
    _ => ({
      init: _.init,
      _decodeIdentity: _.user._decodeIdentity,
      resetUserStore: _.user.resetStore,
    })
  );
  const logout = useLogout();
  const { tenant } = useComputedConfig();

  const [authenticationIsReady, setAuthenticationReady] = useState(false);

  /**
   * Hooks
   */
  const navigate = useNavigate();
  useEffect(() => {
    const token = appStorage.jwt.get();
    if (token) {
      _decodeIdentity(token);
    }

    setAuthenticationReady(true);
  }, []);
  useBrowserDatadog();
  useLocalStorageUpdates(jwt => {
    console.log('Received token from another tab', jwt);
    if (jwt) {
      _decodeIdentity(jwt);
    } else {
      logout();
    }
  });
  const {
    queryParams: { accountId = '' },
  } = handleNavigation<'accountId'>() as {
    queryParams: { accountId: string };
  };

  useEffect(() => {
    if (!identity) {
      return;
    }
    if (!isEmailVerified) {
      navigate(APP_ROUTES.NON_AUTH_EMAIL_VERIFICATION_PENDING);
      return;
    }
    const timer = setTimeout(() => {
      // TODO: tech-debt
      if (window.location.href.includes(APP_ROUTES.AUTH_AUTH0_CALLBACK)) {
        // We should not be authenticated at this stage.
        console.log('Clearing existing authentication data');
        resetUserStore();
        return;
      }
      // TODO: tech-debt
      init({
        client: 'web',
        tenant: tenant,
        throwOnError: true,
        queryParams: { accountId },
      }).catch(e => {
        if (e instanceof ApiError && (e as ApiError).status === 401) {
          document.location.href = APP_ROUTES.NON_AUTH_LOGGED_OUT;
        } else {
          document.location.href = APP_ROUTES.NON_AUTH_500x;
        }
      });
    });
    return () => clearTimeout(timer);
  }, [identity, isEmailVerified]);

  useEffect(() => {
    if (booted === false && error) {
      navigate(APP_ROUTES.NON_AUTH_500x);
    }
  }, []);

  /**
   * DOM
   */
  if (
    isAuthenticated &&
    isEmailVerified &&
    !window.location.href.includes(
      APP_ROUTES.NON_AUTH_EMAIL_VERIFICATION_PENDING
    ) &&
    !booted
  ) {
    return <AppLoader spinnerTop="104px" />;
  }

  if (!authenticationIsReady) {
    return <AppLoader spinnerTop="104px" />;
  }

  return (
    <>
      {/* content  */}
      <div className="bg-grey-brightest h-full">{props.children}</div>
    </>
  );
};
