import { FC } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import {
  Enriched,
  StablehousePusherChannel,
  usePusher,
  useSystemConfig,
  FormHeader,
  factories,
} from 'common';
import cx from 'classnames';
import { AppStore, DataStore } from '../../../store';
import { WithdrawCashSteps } from './steps';
import { getAccountInfo } from '~/utils/get-account-details';
import { getWithdrawCashRecords } from '~/utils/get-activity-records/withdraw-cash';
import { Receipt } from '~/components/receipt';
import { TransactionPeriodNotice } from '../shared/transaction-period-notice';
import { API } from 'api';

export const Summary: FC<Partial<StepWizardChildProps>> = ({
  goToNamedStep,
}) => {
  /**
   * Store
   */
  const accounts = DataStore.useStoreState(s => s.portfolio.accounts);
  const accountDetail = DataStore.useStoreState(s => s.portfolio.accountDetail);
  const formValues = DataStore.useStoreState(s => s.withdrawCash.formValues);
  const withdrawal = DataStore.useStoreState(s => s.withdrawCash.withdrawal);
  const pusher = AppStore.useStoreState(s => s.pusher);
  const fiatCurrencyCodes = DataStore.useStoreState(
    s => s.metaData.fiatCurrencyCodes
  );
  const setWithdrawal = DataStore.useStoreActions(
    a => a.withdrawCash.setWithdrawal
  );

  /**
   * Hooks
   */
  const { envName } = useSystemConfig();
  usePusher(
    pusher,
    StablehousePusherChannel.FIAT_WITHDRAWAL_STATUS,
    envName,
    (err, data) => {
      if (err) {
        console.error(
          `FAILED - Pusher for getting FIAT_WITHDRAWAL status updates`
        );
      } else if (data) {
        setWithdrawal(
          factories.enrichFiatWithdrawal(
            data as API.FiatWithdrawalWithTimeline,
            fiatCurrencyCodes
          )
        );
      }
    },
    withdrawal?.accountId,
    withdrawal?.id
  );
  if (!goToNamedStep) {
    return null;
  }

  /**
   * Methods
   */
  const handleCancel = () =>
    goToNamedStep(WithdrawCashSteps.ConfirmCancellation);

  /**
   * DOM
   */
  if (!formValues || !accountDetail || !withdrawal) {
    return null;
  }
  const account = accounts.find(
    a => a.account?.accountNumber === accountDetail.account?.accountNumber
  );
  const records = getWithdrawCashRecords(withdrawal, 'Pending', account);
  return (
    <div
      className={cx('flex flex-col text-primary', {
        'mb-8': !withdrawal?.canCancel,
      })}
    >
      {/* header  */}
      <FormHeader
        title={`Withdraw ${formValues?.fromAsset?.currency.displayCode || ''}`}
        accountInfo={getAccountInfo(accountDetail.account, 'From')}
      />

      <Receipt
        records={records}
        type={'workflow-withdraw-cash'}
        ccyCodes={[withdrawal.currency?.code]}
      />
      <TransactionPeriodNotice
        status={withdrawal?.status}
        workflow={'workflow-withdraw-cash'}
        accountType={accountDetail?.account?.accountType}
      />
      {/* actions  */}
      {withdrawal?.canCancel && (
        <div className="flex flex-col border-t border-grey-bright bg-gray-100 rounded-b mt-4 py-6 px-6">
          <button
            role="button"
            type="button"
            className="app-button-failure w-full flex-1"
            onClick={handleCancel}
          >
            Cancel this transaction
          </button>
        </div>
      )}
    </div>
  );
};
