import cx from 'classnames';
import {
  AddCashFormikProps,
  AddCashMethod,
  FundShowDepositDetails,
  validateAddCashFormik,
  ValidationMessage,
  FormHeader,
  EnrichedCurrency,
} from 'common';
import { Formik } from 'formik';
import { FC, useEffect } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { AppStore, DataStore } from '../../../store';
import {
  singleCurrencyItemTemplate,
  singleCurrencySelectedItemTemplate,
} from '../../app-selector/templates';
import { SelectField } from '../../forms/select-field';
import { AddCashSteps } from './steps';
import { useValidateOnLoad } from '~/components/forms/use-validate-on-load';
import { getAccountInfo } from '~/utils/get-account-details';
import { useLocation } from 'react-router-dom';
import { API } from 'api';

export const Form: FC<
  Partial<StepWizardChildProps & FundShowDepositDetails>
> = ({ goToNamedStep, fundDetailsType, onClose }) => {
  /**
   * Store
   */
  const clientUserType = DataStore.useStoreState(s => s.user.clientUserType);
  const defaultAccountId = DataStore.useStoreState(
    s => s.user.defaultAccountId
  );
  const formValues = DataStore.useStoreState(s => s.addCash.formValues);
  const allowedCurrencies = DataStore.useStoreState(
    s => s.addCash.allowedCurrencies
  );
  const accountDetail = DataStore.useStoreState(s => s.portfolio.accountDetail);
  const busy = DataStore.useStoreState(s => s.addCash.busy);
  const error = DataStore.useStoreState(s => s.addCash.error);
  const getFiatDepositableCurrencies = DataStore.useStoreActions(
    a => a.addCash.getFiatDepositableCurrencies
  );

  useEffect(() => {
    (async () => {
      await getFiatDepositableCurrencies({
        accountId: accountDetail?.account?.accountId,
      });
    })();
  }, []);

  const { resetState, getDetails, setFormValues } = DataStore.useStoreActions(
    a => ({
      resetState: a.addCash.resetState,
      getDetails: a.addCash.getDetails,
      setFormValues: a.addCash.setFormValues,
    })
  );
  const dashboardSelectedAsset = AppStore.useStoreState(
    s => s.dashboardSelectedAsset
  );
  const { setSelectedDialogType, setDashboardSelectedAsset } =
    AppStore.useStoreActions(a => ({
      setSelectedDialogType: a.setDashboardSelectedDialogType,
      setDashboardSelectedAsset: a.setDashboardSelectedAsset,
    }));

  /**
   * Hooks
   */
  const location = useLocation();

  /**
   * Methods
   */
  const onFormValidate = async (values: AddCashFormikProps) => {
    const errors = validateAddCashFormik(values);
    const hasErrors = Object.keys(errors).length > 0;
    setFormValues(values);
    if (!hasErrors && values.addCashMethod === AddCashMethod.bank) {
      const fund =
        clientUserType === 'admin'
          ? (location.state as API.ApexFund)
          : undefined;
      const accountId =
        clientUserType === 'admin'
          ? defaultAccountId
          : accountDetail?.account?.accountId;
      const impersonatedAccountId =
        clientUserType === 'admin' ? fund?.accountId : undefined;
      await getDetails({
        currencyCode: values.toAsset?.code || '',
        accountId,
        impersonatedAccountId,
      });
    }
    return errors;
  };
  const onFormSubmit = () => {
    if (goToNamedStep) {
      goToNamedStep(AddCashSteps.Preview);
    }
  };
  const getAllowedDepositMethods = () => {
    const result: AddCashMethod[] = [];
    result.push(AddCashMethod.bank);
    return result;
  };

  /**
   * DOM
   */
  const allowedDepositMethods = getAllowedDepositMethods();
  const initialValues: AddCashFormikProps = formValues || {
    addCashMethod: allowedDepositMethods[0],
    toAsset: dashboardSelectedAsset?.currency || null,
    note: '',
    amount: '',
    cvv: '',
  };
  return (
    <div className="flex flex-col text-primary relative">
      {/* form  */}
      <Formik<AddCashFormikProps>
        initialValues={initialValues}
        validate={onFormValidate}
        validateOnBlur={false}
        validateOnMount={false}
        validateOnChange
        onSubmit={onFormSubmit}
      >
        {({
          values,
          errors,
          isValidating,
          submitForm,
          validateForm,
          initialValues: onLoadValues,
        }) => {
          /**
           * Hooks
           */
          useValidateOnLoad(initialValues, validateForm, () => {
            return (
              !!onLoadValues.toAsset &&
              values.addCashMethod === AddCashMethod.bank
            );
          });

          /**
           * Methods
           */
          const onFiatFundChanged = async () => {
            if (isValidating) {
              return;
            }
          };
          const onCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            setDashboardSelectedAsset(null);
            setSelectedDialogType(null);
            resetState();
            if (fundDetailsType && onClose) onClose();
          };
          const onNext = (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            submitForm();
          };

          /**
           * Form DOM
           */
          const hasErrors = Object.keys(errors).length > 0;
          const isDisabled =
            isValidating || error !== null || hasErrors || !formValues;
          return (
            <>
              {/* header  */}
              <FormHeader
                title={
                  fundDetailsType
                    ? `Select currency`
                    : `Add ${values.toAsset?.displayCode || ''}`
                }
                accountInfo={getAccountInfo(accountDetail?.account, 'To')}
              />
              {/* fields  */}
              <div className="px-6 md:px-10">
                {/* select fiat  */}
                <div
                  className={cx('flex flex-col sm:flex-row gap-x-5', {
                    hidden: values.addCashMethod !== AddCashMethod.bank,
                  })}
                >
                  <div className="flex flex-col sm:mb-0 sm:flex-1">
                    <SelectField
                      name="toAsset"
                      label="Select currency"
                      values={allowedCurrencies}
                      loadingValues={busy}
                      getSelectedItemTemplate={curr =>
                        singleCurrencySelectedItemTemplate(curr)
                      }
                      getItemTemplate={(
                        curr,
                        selected: boolean,
                        active: boolean
                      ) => singleCurrencyItemTemplate(curr, selected, active)}
                      onChange={onFiatFundChanged}
                    />
                  </div>
                </div>
              </div>

              <ValidationMessage message={error} cls="mx-10" type="global" />

              {/* actions  */}
              <div className="flex flex-col gap-y-4 gap-x-3 sm:flex-row border-t border-grey-bright bg-gray-100 rounded-b py-6 px-6 md:px-10 mt-12">
                {/* cancel button  */}
                <button
                  className="app-button-outline w-full flex-1"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                {/* withdraw button  */}
                <button
                  disabled={isDisabled}
                  type="button"
                  className="app-button-primary flex-1 flex items-center justify-center"
                  onClick={onNext}
                >
                  Next
                </button>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};
