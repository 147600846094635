var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Text as Paragraph } from 'react-native';
import { _AmountFormat, } from '../../molecules/amount-format';
import { TextBody } from '../../atoms/text';
export const AmountIssuance = memo((_a) => {
    var { currency } = _a, rest = __rest(_a, ["currency"]);
    return (_jsxs(Paragraph, { children: [_jsx(TextBody, Object.assign({ color: rest.color, em: true }, { children: currency })), ' ', _jsx(_AmountFormat, Object.assign({}, rest, { trimMantissa: true, decimals: 2, shorten: true }))] }));
});
