import { FC } from 'react';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../routes';
import { useEffect } from 'react';
import { appStorage } from '../../utils/app-storage';
import { OpenLayout } from '../layouts/open-layout';

export const AccountLocked: FC = () => {
  /**
   * Hooks
   */
  useEffect(() => {
    appStorage.jwt.remove();
  }, []);

  /**
   * DOM
   */
  return (
    <OpenLayout
      icon="negative-circle-shield"
      title="Your account has been locked."
      cta={
        <Link
          role="button"
          className="app-button-primary mt-12"
          style={{ width: 275 }}
          to={APP_ROUTES.NON_AUTH_HOME}
        >
          Go to sign in
        </Link>
      }
      withAppWrapper
    >
      <>
        You will need to reset your password, please{' '}
        <strong>check your email</strong> for instructions to unlock your
        account.
        <br />
        <br />
        If you need further assistance please{' '}
        <Link to={APP_ROUTES.NON_AUTH_CONTACT_US}>
          <strong>contact support.</strong>
        </Link>
      </>
    </OpenLayout>
  );
};
