import { JWT_KEYS, AppStorage } from 'common';

const tenantKey = `stablehouse-app-tenant`;

export const appStorage: AppStorage = {
  jwt: {
    get: () => localStorage.getItem(JWT_KEYS.STORAGE_KEY),
    set: (token: string) => localStorage.setItem(JWT_KEYS.STORAGE_KEY, token),
    remove: () => localStorage.removeItem(JWT_KEYS.STORAGE_KEY),
  },
  tenant: {
    get: () => localStorage.getItem(tenantKey),
    set: (token: string) => localStorage.setItem(tenantKey, token),
    remove: () => localStorage.removeItem(tenantKey),
  },
  alerts: {
    get: () => localStorage.getItem('stablehouse-alerts'),
    set: (alerts: string) => localStorage.setItem('stablehouse-alerts', alerts),
    remove: () => localStorage.removeItem('stablehouse-alerts'),
  },
};
