export enum BlockchainNetworkName {
  ETH = 'Ethereum (ERC20)',
  XLM = 'Stellar',
  LIQUID = 'Liquid',
  BTC = 'Bitcoin',
  BSC = 'Binance Smart Chain (BEP20)',
  ADA = 'Cardano',
  POLYGON = 'Polygon',
  SOL = 'Solana',
  BASE = 'Base',
}
