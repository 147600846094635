import { Actions, Helpers } from 'easy-peasy';
import { DataModel } from '../store/data-store';
import { ApiError } from '../store/api-error';
import { ApiResponse, ThunkResult, Injections } from '../store/types';
import { BaseModel } from '../store/base-store';

export async function runApi<T>(
  actions: Actions<BaseModel>,
  helpers: Helpers<BaseModel, DataModel, Injections>,
  execute: () => Promise<ApiResponse<T>>,
  onSuccess?: (result: T) => void,
  headers?: {
    'x-account-id': string | null | undefined;
    'x-impersonated-account-id'?: string;
  },
  silent = false
): Promise<ThunkResult<T>> {
  if (!silent) {
    actions.setBusy(true);
  }

  const state = helpers.getStoreState();

  try {
    helpers.injections.apiClient.setAdditionalHeaders(
      headers ?? {
        // Note: DO NOT CHANGE THIS `default`. If you need to talk to @jey to clarify
        'x-account-id':
          state.portfolio.accountDetail?.account?.accountId ??
          state.user.defaultAccountId,
      }
    );

    const { result } = await execute();

    if (result) {
      onSuccess?.(result);
    }

    return {
      isSuccessful: true,
      result,
    };
  } catch (err) {
    if (err instanceof ApiError) {
      actions.setError(err);

      return {
        isSuccessful: false,
        errorCode: err.code,
        errorMessage: err.message,
        status: err.status,
      };
    }

    throw err;
  } finally {
    if (!silent) {
      actions.setBusy(false);
    }
  }
}
