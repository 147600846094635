import { API } from 'api';
import { FC } from 'react';
import cx from 'classnames';
import { StepWizardChildProps } from 'react-step-wizard';
import {
  SendDestinationType,
  usePusher,
  useSystemConfig,
  useCurrencyInfoContext,
  FormHeader,
} from 'common';
import { AppStore, DataStore } from '../../../store';
import { getSendRecords } from '~/utils/get-activity-records/send';
import { getAccountInfo } from '~/utils/get-account-details';
import { Receipt } from '~/components/receipt';
import { SendSteps } from './steps';
import { TransactionPeriodNotice } from '../shared/transaction-period-notice';

export const Summary: FC<Partial<StepWizardChildProps>> = ({
  goToNamedStep,
}) => {
  if (!goToNamedStep) {
    return null;
  }

  /**
   * Store
   */
  const formValues = DataStore.useStoreState(s => s.send.formValues);
  const withdrawal = DataStore.useStoreState(s => s.send.withdrawal);
  const currencies = DataStore.useStoreState(s => s.metaData.currencies);
  const accountDetail = DataStore.useStoreState(s => s.portfolio.accountDetail);
  const accounts = DataStore.useStoreState(s => s.portfolio.accounts);

  const pusher = AppStore.useStoreState(s => s.pusher);
  const setWithdrawal = DataStore.useStoreActions(s => s.send.setWithdrawal);

  /**
   * Hooks
   */
  const { envName } = useSystemConfig();
  usePusher(
    pusher,
    withdrawal?.channelName,
    envName,
    (err, data) => {
      if (err) {
        console.error(`FAILED - Pusher for getting SEND status updates`);
        return;
      }
      setWithdrawal(data as API.Withdrawal | API.Transfer);
    },
    withdrawal?.accountId,
    withdrawal?.id
  );
  const ccyInfo = useCurrencyInfoContext(withdrawal?.currencyCode);

  /**
   * Methods
   */
  const handleCancel = () => goToNamedStep(SendSteps.ConfirmCancellation);

  /**
   * DOM
   */
  if (!formValues || !accountDetail || !withdrawal) {
    return null;
  }
  const account = accounts.find(
    a => a.account?.accountNumber === accountDetail.account?.accountNumber
  );
  const records = getSendRecords(
    withdrawal,
    withdrawal.status,
    currencies,
    account,
    !!(formValues.destinationType === SendDestinationType.transfer)
  );
  return (
    <div
      className={cx('flex flex-col text-primary', {
        'mb-8': !withdrawal?.canCancel,
      })}
    >
      {/* header  */}
      <FormHeader
        title={`Sent ${ccyInfo?.displayCode}`}
        accountInfo={getAccountInfo(accountDetail.account, 'From')}
      />

      <Receipt
        records={records}
        type={'workflow-send'}
        ccyCodes={[withdrawal.currencyCode]}
      />
      <TransactionPeriodNotice
        cls="mt-4 mb-8"
        workflow={'workflow-send'}
        status={withdrawal?.status}
        accountType={accountDetail?.account?.accountType}
      />

      {/* actions  */}
      {withdrawal?.canCancel && (
        <div className="flex flex-col border-t border-grey-bright bg-gray-100 rounded-b py-6 px-6">
          <button
            role="button"
            type="button"
            className="app-button-failure w-full flex-1"
            onClick={handleCancel}
          >
            Cancel this transaction
          </button>
        </div>
      )}
    </div>
  );
};
