import React, { FC } from 'react';
import { AppIcon } from '../app-icon';
import { TextInput } from '../form/text-input';

type SearchInputProps = {
  cls?: string;
  textCls?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  leftAddon?: JSX.Element;
  disabled?: boolean;
  handleClearInput?: () => void;
  value: string | number | undefined;
  autoFocus?: boolean;
};

/**
 * @deprecated
 * Use universal-components/src/search-input instead
 */
export const SearchInput: FC<SearchInputProps> = ({
  value,
  onChange,
  autoFocus,
  handleClearInput,
  textCls = 'w-full',
  disabled = false,
  cls = 'flex relative items-center mx-8 mr-10 pb-8',
  leftAddon = <AppIcon icon="search-input" bg="bg-transparent" size={18} />,
}) => {
  /**
   * DOM
   */
  return (
    <div className={cls}>
      <TextInput
        value={value}
        minLength={2}
        type={'text'}
        disabled={disabled}
        onChange={onChange}
        clsWrapper={textCls}
        leftAddon={leftAddon}
        autoFocus={autoFocus}
        leftAddonBorder={false}
        handleClearInput={handleClearInput}
      />
    </div>
  );
};
