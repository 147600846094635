import { jsx as _jsx } from "react/jsx-runtime";
/**
 * Note:
 *
 * `@xbto/universal-components` is built with RN & resolved via RNW for use in our web application
 *
 * When these components are rendered in the DOM, they are prone to CSS (cascading) & any `!important` styles override the defaults of the UC.
 * The fix to override the cascaded styles would be to enforce UC's to have imporatnt, which is not ideal.
 *
 * Instead, decided to render the UCs in web app throgh this resolver per-se which will inject the UC inside a shadow DOM, thus
 * preventing styles from polluting its eco-system
 */
import { useRef, useEffect } from 'react';
import { Platform, View } from 'react-native';
// TODO(@jey) - once we update to expo 52, the render function is part of default exports
import { render } from 'react-native-web/dist/exports/render';
function WrapInShadowDOM({ children, options, }) {
    /**
     * Vars
     */
    const shadowHost = useRef();
    const reactRoot = useRef();
    useEffect(() => {
        if (shadowHost.current) {
            if (!reactRoot.current) {
                // @ts-ignore
                const shadowRoot = shadowHost.current.attachShadow({ mode: 'open' });
                reactRoot.current = render(children, shadowRoot);
            }
            // @ts-ignore
            reactRoot.current.render(children);
        }
    });
    /**
     * DOM
     */
    // @ts-ignore
    return _jsx(View, { ref: shadowHost, style: { zIndex: (options === null || options === void 0 ? void 0 : options.zIndex) || 0 } });
}
/**
 * HOC to render a given component inside shadowDOM
 * @param
 * @returns
 */
export function withShadowDOM(WrappedComponent, options) {
    // Try to create a nice displayName for React Dev Tools.
    const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    // Creating the inner component. The calculated Props type here is the where the magic happens.
    const InnerComponent = (props) => {
        if (Platform.OS === 'web') {
            return (_jsx(WrapInShadowDOM, Object.assign({ options: options }, { children: _jsx(WrappedComponent, Object.assign({}, props)) })));
        }
        return _jsx(WrappedComponent, Object.assign({}, props));
    };
    InnerComponent.displayName = `withShadowDOM(${displayName})`;
    return InnerComponent;
}
