import { API } from 'api';
import { API_ERROR_CODES } from 'common';
import { ThunkCreator } from 'easy-peasy';
import { SendSteps } from './steps';

export const createSendHelper = async (
  _googleAuthenticatorCode: string | null, // depreacated (Auth0)
  create: ThunkCreator<void>,

  onNext: (step: SendSteps) => void,
  onAbort?: () => void,
  onCreated?: (data: API.Transfer | API.Withdrawal) => void
) => {
  const { isSuccessful: created, errorCode, result } = await create();

  if (!created || !result) {
    if (errorCode) {
      if (errorCode === API_ERROR_CODES.USER_ACOUNT_LOCKED) {
        onNext(SendSteps.AccountLocked);
      }
      if (errorCode === API_ERROR_CODES.WITHDRAW_TRANSFER_ABORTED) {
        onAbort?.();
      }
    }
    return;
  }

  if (created && result) {
    onCreated?.(result);
  }

  const isPendingEmailConfirm =
    result.status === API.WithdrawalStatus.PendingUserEmailConfirmation ||
    result.status === API.TransferStatus.WaitingForConfirmation;

  if (isPendingEmailConfirm && result?.requireEmailCodeVerification) {
    onNext(SendSteps.Code);
    return;
  }

  onNext(SendSteps.Summary);
};
