import React, { FC, isValidElement } from 'react';
import { appIconMap } from './map';
import cx from 'classnames';
import { BaseComponentProps } from '../../types';

export type AppIconType =
  | 'flag-outline'
  | 'lock-2fa'
  | 'user'
  | 'hamburger-menu'
  | 'hamburger-menu-close'
  | 'sort-asc'
  | 'sort-desc'
  | 'information-circle'
  | 'institutions'
  | 'check-circle'
  | 'chat-check'
  | 'cross'
  | 'close-button'
  | 'cross-circle'
  | 'percent'
  | 'credit-card-rounded'
  | 'check-shield'
  | 'kyc-shield'
  | 'check-filled'
  | 'chat-filled'
  | 'pencil'
  | 'check'
  | 'copy'
  | 'green-tick'
  | 'circle-shield-cross'
  | 'credit-card-amex'
  | 'credit-card-diners-club'
  | 'credit-card-jcb'
  | 'credit-card-mastercard'
  | 'credit-card-visa'
  | 'deposit'
  | 'withdraw'
  | 'convert'
  | 'percent-circle'
  | 'chat'
  | 'dollar-circle'
  | 'info'
  | 'thumbs-up'
  | 'pin'
  | 'fiat-currencies-group'
  | 'fiat-crypto-currencies-group'
  | 'heart-circle'
  | 'btc-circle'
  | 'sheid-circle'
  | 'double-quote-accent'
  | 'arrow'
  | 'referred'
  | 'check-simple'
  | 'chevron-right'
  | 'plus'
  | 'plus-accent'
  | 'bank-account'
  | 'crypto-addresses'
  | 'mail-notifications'
  | 'verification'
  | 'padlock-unlock'
  | 'padlock'
  | 'calendar'
  | 'interest'
  | 'csv'
  | 'airplane'
  | 'cross-with-circle'
  | 'mobile'
  | 'question-dot'
  | 'gift'
  | 'email'
  | 'redeem'
  | 'card'
  | 'workflow-add-cash'
  | 'workflow-buy'
  | 'workflow-sell'
  | 'workflow-receive'
  | 'workflow-send'
  | 'workflow-withdraw-cash'
  | 'workflow-transfer'
  | 'send'
  | 'negative-circle-vault'
  | 'negative-circle-transfer'
  | 'negative-circle-shield'
  | 'negative-circle-shield-check'
  | 'negative-circle-flag'
  | 'logo-circle'
  | 'negative-chat-check'
  | 'chevron-down-dark'
  | 'chevron-up-accent'
  | 'email-checked'
  | 'email-checked-light'
  | 'id-card'
  | 'stablehouse-lettermark'
  | 'social-twitter'
  | 'social-linkedin'
  | 'social-facebook'
  | 'social-telegram'
  | 'negative-pencil'
  | 'negative-twitter'
  | 'negative-people-voices'
  | 'negative-refer-a-friend'
  | 'manage-history'
  | 'telegram'
  | 'envelope'
  | 'exclamation'
  | 'exclamation-circle'
  | 'pencil-edit'
  | 'pencil-line'
  | 'calendar-date'
  | 'check-shield-white'
  | 'cross-shield-white'
  | 'negative-check-shield'
  | 'balance'
  | 'insights-pulse'
  | 'insights-update'
  | 'twitter-gray'
  | 'ios-app-store'
  | 'google-app-store'
  | 'stablehouse-footer-logo'
  | 'pof-black-shield-check'
  | 'pof-white-shield-check'
  | 'pof-white-shield-cross'
  | 'triangle-down'
  | 'solutions-custody'
  | 'solutions-trading'
  | 'solutions-white-label'
  | 'client-building'
  | 'client-intermediaries'
  | 'client-investors'
  | 'stablehouse-lettermark-dark'
  | 'bma'
  | 'search-input'
  | 'manage-search'
  | 'search-off'
  | 'stacked-coins'
  | 'key-facts-pencil'
  | 'why-relevant-pin'
  | 'chevron-back'
  | 'export'
  | 'arrow-north-east'
  | 'arrow-dropup'
  | 'arrow-dropdown'
  | 'trading-account-arrows'
  | 'holdings-info'
  | 'custody-account-vault'
  | 'handshake'
  | 'refresh'
  | 'circle-shield-check'
  | 'chevron-icon-drop-down'
  | 'external-circle'
  | 'arrow-right'
  | 'check-success'
  | 'trading-account-arrows-filled'
  | 'custody-account-vault-filled'
  | 'stablehouse-products'
  | 'shield-plus'
  | 'logo-stablehouse'
  | 'calendar-caret'
  | 'filter'
  | 'download'
  | 'arrow-back'
  | 'chevron-grey-right'
  | 'visibility-show'
  | 'user-placeholder'
  | 'markets-graph'
  | 'graph-line'
  | 'statement'
  | 'invoice'
  | 'settings'
  | 'approvals'
  | 'friend-referral'
  | 'sign-out'
  | 'person'
  | 'stablehouse-logo'
  | 'candlestick'
  | 'lifebuoy'
  | 'graduation-cap'
  | 'custody-account-vault-filled-solid'
  | 'stablehouse-telegram-logo'
  | 'xbto-logo-icon'
  | 'arrow-external'
  | 're-order'
  | 'logo-xbtohub-black'
  | 'logo-xbtohub-white'
  | 'logo-xbto-powered-by-stablehouse'
  | 'logo-xbtohub-powered-by-stablehouse-stacked'
  | 'logo-powered-by-stablehouse-stacked'
  | 'british-flag'
  | 'check-info'
  | 'market-trends'
  | 'rj-app-logo-header'
  | 'rj-app-logo-footer'
  | 'apex-app-logo-header'
  | 'dots'
  | 'in-progress'
  | 'blank-icon'
  | 'chevron-down'
  | 'asset-wUSDM'
  | 'logo-xbto-round'
  | 'chevron-right-alt'
  | 'lock-clock'
  | 'graph-candlestick'
  | 'graph-area'
  | 'shield-security';

export type IconSize = 'lg' | 'md' | 'sm' | number;

export enum IconSizes {
  Large = 60,
  Medium = 30,
  Small = 20,
}

export interface Props extends BaseComponentProps {
  icon: AppIconType | JSX.Element;
  size?: IconSize;
  height?: IconSize;
  fill?: string;
  cls?: string;
  bg?: string;
}

export const getSize = (size?: IconSize) => {
  if (!size) {
    return IconSizes.Medium;
  }
  if (size === 'lg') {
    return IconSizes.Large;
  }
  if (size === 'md') {
    return IconSizes.Medium;
  }
  if (size === 'sm') {
    return IconSizes.Small;
  }
  return size;
};

const Wrapper: FC<Props> = ({
  cls = 'inline-block items-center justify-center svg-stretch-w-h',
  bg = 'bg-accent-light',
  fill = '',
  size = 40,
  height,
  children,
}) => {
  const standardSize = getSize(size);
  const standardHeight = getSize(height || size);
  return (
    <span
      style={{
        height: standardHeight,
        width: standardSize,
      }}
      className={cx('relative flex rounded-full', bg, cls, fill)}
    >
      {children}
    </span>
  );
};

/**
 * @deprecated
 * Migrate to universal-components/src/lib/icon as much as possible (import SVG icon in UC)
 */
export const AppIcon: FC<Props> = props => {
  /**
   * DOM
   */
  if (!props || !props.icon) {
    return null;
  }
  if (isValidElement(props.icon)) {
    return (
      <Wrapper {...props} cls="inline-block items-center justify-center">
        {props.icon}
      </Wrapper>
    );
  }
  const standardSize = getSize(props.size);
  const el = appIconMap[props.icon as AppIconType](standardSize);
  return <Wrapper {...props}>{el}</Wrapper>;
};
