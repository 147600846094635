import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { Pressable, StyleSheet } from 'react-native';
import { resetZindexOptionally } from '../../utils';
import { withShadowDOM } from '../../hoc';
import { createStyles } from '../../theme';
export function TableCell({ children, onPress, renderInShadowDOM, width, align, Wrapper, }) {
    const computedStyles = StyleSheet.flatten(resetZindexOptionally([
        $.container,
        { width },
        {
            justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
        },
    ]));
    const content = renderInShadowDOM
        ? withShadowDOM(() => _jsx(_Fragment, { children: children }))
        : children;
    if (Wrapper !== undefined) {
        return _jsx(Wrapper, Object.assign({ style: computedStyles }, { children: content }));
    }
    else {
        return (_jsx(Pressable, Object.assign({ onPress: onPress, disabled: !onPress, style: computedStyles }, { children: content })));
    }
}
const $ = createStyles({
    container: {
        row: 4,
        borderBottomWidth: 1,
        borderColor: 'outline',
        p: 's',
    },
});
