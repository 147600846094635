var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { StyleSheet } from 'react-native';
import { TableCell } from '../../molecules/table-cell';
import { Row } from '../../atoms/row';
import { Col } from '../../atoms/col';
import { TextBody, TextSubheading } from '../../atoms/text';
import { ApyLabel } from '../../organisms/apy-label';
export const TableCellAsset = memo((_a) => {
    var { apy, coinIcon, displayCode, name, trending } = _a, rest = __rest(_a, ["apy", "coinIcon", "displayCode", "name", "trending"]);
    return (_jsx(TableCell, Object.assign({}, rest, { children: _jsxs(Row.L, { children: [coinIcon && _jsx(Col, Object.assign({ mr: "m" }, { children: coinIcon })), _jsxs(Col, Object.assign({ style: $.row }, { children: [_jsxs(Row.L, { children: [_jsx(TextSubheading, { children: displayCode }), apy > 0 && (_jsx(Col, Object.assign({ ml: "xxs" }, { children: _jsx(ApyLabel, { value: apy }) }))), !!trending && _jsx(TextSubheading, Object.assign({ ml: "xxs" }, { children: "\uD83D\uDD25" }))] }), _jsx(TextBody, Object.assign({ small: true }, { children: name }))] }))] }) })));
});
const $ = StyleSheet.create({
    row: {
        rowGap: 2,
    },
});
