import React, { Fragment } from 'react';
import styles from './index.module.scss';
import cx from 'classnames';

import { useCurrencyInfoContext } from '../../hooks';
import { CurrencyIconKey, CurrencyIconProps } from './types';

// digital ccys
import { ReactComponent as Ada } from '../../assets/currencies/ada.svg';
import { ReactComponent as Btc } from '../../assets/currencies/btc.svg';
import { ReactComponent as Dai } from '../../assets/currencies/dai.svg';
import { ReactComponent as Gusd } from '../../assets/currencies/gusd.svg';
import { ReactComponent as Lbtc } from '../../assets/currencies/lbtc.svg';
import { ReactComponent as Lusdt } from '../../assets/currencies/lusdt.svg';
import { ReactComponent as Sai } from '../../assets/currencies/sai.svg';
import { ReactComponent as Usds } from '../../assets/currencies/usds.svg';
import { ReactComponent as Usdte } from '../../assets/currencies/usdte.svg';
import { ReactComponent as Susd } from '../../assets/currencies/susd.svg';
import { ReactComponent as Qcad } from '../../assets/currencies/qcad.svg';
import { ReactComponent as Schf } from '../../assets/currencies/schf.svg';
import { ReactComponent as Seur } from '../../assets/currencies/seur.svg';
import { ReactComponent as Saud } from '../../assets/currencies/saud.svg';
import { ReactComponent as Sjpy } from '../../assets/currencies/sjpy.svg';
import { ReactComponent as Tusd } from '../../assets/currencies/tusd.svg';
import { ReactComponent as Usdc } from '../../assets/currencies/usdc.svg';
import { ReactComponent as Usdt } from '../../assets/currencies/usdt.svg';
import { ReactComponent as Eurs } from '../../assets/currencies/eurs.svg';
import { ReactComponent as Eth } from '../../assets/currencies/eth.svg';
import { ReactComponent as Scad } from '../../assets/currencies/scad.svg';
import { ReactComponent as Xlm } from '../../assets/currencies/xlm.svg';
import { ReactComponent as Busd } from '../../assets/currencies/busd.svg';
import { ReactComponent as Husd } from '../../assets/currencies/husd.svg';
import { ReactComponent as Paxg } from '../../assets/currencies/paxg.svg';
import { ReactComponent as Renbtc } from '../../assets/currencies/renbtc.svg';
import { ReactComponent as Wbtc } from '../../assets/currencies/wbtc.svg';
import { ReactComponent as Xau } from '../../assets/currencies/xau.svg';
import { ReactComponent as Xaut } from '../../assets/currencies/xaut.svg';
import { ReactComponent as Bnb } from '../../assets/currencies/bnb.svg';
import { ReactComponent as Cro } from '../../assets/currencies/cro.svg';
import { ReactComponent as Crv } from '../../assets/currencies/crv.svg';
import { ReactComponent as Ftt } from '../../assets/currencies/ftt.svg';
import { ReactComponent as Link } from '../../assets/currencies/link.svg';
import { ReactComponent as Mana } from '../../assets/currencies/mana.svg';
import { ReactComponent as Matic } from '../../assets/currencies/matic.svg';
import { ReactComponent as Snx } from '../../assets/currencies/snx.svg';
import { ReactComponent as Sushi } from '../../assets/currencies/sushi.svg';
import { ReactComponent as Tgbp } from '../../assets/currencies/tgbp.svg';
import { ReactComponent as Uni } from '../../assets/currencies/uni.svg';
import { ReactComponent as Usdp } from '../../assets/currencies/usdp.svg';
import { ReactComponent as Eurt } from '../../assets/currencies/eurt.svg';
import { ReactComponent as Aave } from '../../assets/currencies/aave.svg';
import { ReactComponent as Yfi } from '../../assets/currencies/yfi.svg';
import { ReactComponent as Comp } from '../../assets/currencies/comp.svg';
import { ReactComponent as Dydx } from '../../assets/currencies/dydx.svg';
import { ReactComponent as Axs } from '../../assets/currencies/axs.svg';
import { ReactComponent as Enj } from '../../assets/currencies/enj.svg';
import { ReactComponent as Gno } from '../../assets/currencies/gno.svg';
import { ReactComponent as Lrc } from '../../assets/currencies/lrc.svg';
import { ReactComponent as Bat } from '../../assets/currencies/bat.svg';
import { ReactComponent as Bnt } from '../../assets/currencies/bnt.svg';
import { ReactComponent as Zrx } from '../../assets/currencies/zrx.svg';
import { ReactComponent as OneInch } from '../../assets/currencies/1inch.svg';
import { ReactComponent as Eurc } from '../../assets/currencies/eurc.svg';
import { ReactComponent as Eurr } from '../../assets/currencies/eurr.svg';
import { ReactComponent as Pyth } from '../../assets/currencies/pyth.svg';
import { ReactComponent as Sol } from '../../assets/currencies/sol.svg';
import { ReactComponent as Bonk } from '../../assets/currencies/bonk.svg';
import { ReactComponent as Wusdm } from '../../assets/currencies/wusdm.svg';
import { ReactComponent as Susde } from '../../assets/currencies/susde.svg';
import { ReactComponent as Shib } from '../../assets/currencies/shib.svg';
import { ReactComponent as Ldo } from '../../assets/currencies/ldo.svg';
import { ReactComponent as Rndr } from '../../assets/currencies/rndr.svg';
import { ReactComponent as Ena } from '../../assets/currencies/ena.svg';
import { ReactComponent as Pols } from '../../assets/currencies/pols.svg';
import { ReactComponent as Ern } from '../../assets/currencies/ern.svg';
import { ReactComponent as Myth } from '../../assets/currencies/myth.svg';
import { ReactComponent as Naym } from '../../assets/currencies/naym.svg';
import { ReactComponent as EthBase } from '../../assets/currencies/eth-base.svg';

// fiat ccys
import { ReactComponent as Aud } from '../../assets/currencies/aud.svg';
import { ReactComponent as Eur } from '../../assets/currencies/eur.svg';
import { ReactComponent as Gbp } from '../../assets/currencies/gbp.svg';
import { ReactComponent as Jpy } from '../../assets/currencies/jpy.svg';
import { ReactComponent as Usd } from '../../assets/currencies/usd.svg';
import { ReactComponent as Cad } from '../../assets/currencies/cad.svg';
import { ReactComponent as Chf } from '../../assets/currencies/chf.svg';
import { ReactComponent as Bmd } from '../../assets/currencies/bmd.svg';
import { ReactComponent as Hkd } from '../../assets/currencies/hkd.svg';

// bond tokens
import { ReactComponent as BermudAir } from '../../assets/currencies/bermudair.svg';
import { ReactComponent as Agrorefiner } from '../../assets/currencies/agrorefiner.svg';

// fund tokens
import { ReactComponent as Fastsp } from '../../assets/currencies/fastsp.svg';

// fallback
import { ReactComponent as Fallback } from '../../assets/currencies/fallback.svg';
import { Badge } from '../badges';

const svgIconMap = (
  key: CurrencyIconKey
): ((props: CurrencyIconProps) => JSX.Element) => {
  switch (key) {
    case CurrencyIconKey.ADA:
      return ({ size }) => <Ada width={size} height={size} />;
    case CurrencyIconKey.BTC:
      return ({ size }) => <Btc width={size} height={size} />;
    case CurrencyIconKey.DAI:
      return ({ size }) => <Dai width={size} height={size} />;
    case CurrencyIconKey.GUSD:
      return ({ size }) => <Gusd width={size} height={size} />;
    case CurrencyIconKey.QCAD:
      return ({ size }) => <Qcad width={size} height={size} />;
    case CurrencyIconKey.SCHF:
      return ({ size }) => <Schf width={size} height={size} />;
    case CurrencyIconKey.SAUD:
      return ({ size }) => <Saud width={size} height={size} />;
    case CurrencyIconKey.SEUR:
      return ({ size }) => <Seur width={size} height={size} />;
    case CurrencyIconKey.SJPY:
      return ({ size }) => <Sjpy width={size} height={size} />;
    case CurrencyIconKey.SUSD:
      return ({ size }) => <Susd width={size} height={size} />;
    case CurrencyIconKey.TUSD:
      return ({ size }) => <Tusd width={size} height={size} />;
    case CurrencyIconKey.USDC:
      return ({ size }) => <Usdc width={size} height={size} />;
    case CurrencyIconKey.USDC_POLYGON:
      return ({ size }) => <Usdc width={size} height={size} />;
    case CurrencyIconKey.USDC_POLYGON_NXTB:
      return ({ size }) => <Usdc width={size} height={size} />;
    case CurrencyIconKey.USDT:
      return ({ size }) => <Usdt width={size} height={size} />;
    case CurrencyIconKey.LBTC:
      return ({ size }) => <Lbtc width={size} height={size} />;
    case CurrencyIconKey.LUSDT:
      return ({ size }) => <Lusdt width={size} height={size} />;
    case CurrencyIconKey.SAI:
      return ({ size }) => <Sai width={size} height={size} />;
    case CurrencyIconKey.USDS:
      return ({ size }) => <Usds width={size} height={size} />;
    case CurrencyIconKey.USDTE:
      return ({ size }) => <Usdte width={size} height={size} />;
    case CurrencyIconKey.EURS:
      return ({ size }) => <Eurs width={size} height={size} />;
    case CurrencyIconKey.EUR:
      return ({ size }) => <Eur width={size} height={size} />;
    case CurrencyIconKey.JPY:
      return ({ size }) => <Jpy width={size} height={size} />;
    case CurrencyIconKey.USD:
      return ({ size }) => <Usd width={size} height={size} />;
    case CurrencyIconKey.AUD:
      return ({ size }) => <Aud width={size} height={size} />;
    case CurrencyIconKey.CAD:
      return ({ size }) => <Cad width={size} height={size} />;
    case CurrencyIconKey.CHF:
      return ({ size }) => <Chf width={size} height={size} />;
    case CurrencyIconKey.ETH:
      return ({ size }) => <Eth width={size} height={size} />;
    case CurrencyIconKey.SCAD:
      return ({ size }) => <Scad width={size} height={size} />;
    case CurrencyIconKey.XLM:
      return ({ size }) => <Xlm width={size} height={size} />;
    case CurrencyIconKey.BUSD:
      return ({ size }) => <Busd width={size} height={size} />;
    case CurrencyIconKey.HUSD:
      return ({ size }) => <Husd width={size} height={size} />;
    case CurrencyIconKey.PAXG:
      return ({ size }) => <Paxg width={size} height={size} />;
    case CurrencyIconKey.RENBTC:
      return ({ size }) => <Renbtc width={size} height={size} />;
    case CurrencyIconKey.WBTC:
      return ({ size }) => <Wbtc width={size} height={size} />;
    case CurrencyIconKey.XAU:
      return ({ size }) => <Xau width={size} height={size} />;
    case CurrencyIconKey.XAUT:
      return ({ size }) => <Xaut width={size} height={size} />;
    case CurrencyIconKey.GBP:
      return ({ size }) => <Gbp width={size} height={size} />;
    case CurrencyIconKey.BNB:
      return ({ size }) => <Bnb width={size} height={size} />;
    case CurrencyIconKey.CRO:
      return ({ size }) => <Cro width={size} height={size} />;
    case CurrencyIconKey.CRV:
      return ({ size }) => <Crv width={size} height={size} />;
    case CurrencyIconKey.FTT:
      return ({ size }) => <Ftt width={size} height={size} />;
    case CurrencyIconKey.LINK:
      return ({ size }) => <Link width={size} height={size} />;
    case CurrencyIconKey.MANA:
      return ({ size }) => <Mana width={size} height={size} />;
    case CurrencyIconKey.MATIC:
      return ({ size }) => <Matic width={size} height={size} />;
    case CurrencyIconKey.MATIC_ERC20:
      return ({ size }) => <Matic width={size} height={size} />;
    case CurrencyIconKey.POL_ERC20:
      return ({ size }) => <Matic width={size} height={size} />;
    case CurrencyIconKey.SNX:
      return ({ size }) => <Snx width={size} height={size} />;
    case CurrencyIconKey.SUSHI:
      return ({ size }) => <Sushi width={size} height={size} />;
    case CurrencyIconKey.TGBP:
      return ({ size }) => <Tgbp width={size} height={size} />;
    case CurrencyIconKey.UNI:
      return ({ size }) => <Uni width={size} height={size} />;
    case CurrencyIconKey.USDP:
      return ({ size }) => <Usdp width={size} height={size} />;
    case CurrencyIconKey.EURT:
      return ({ size }) => <Eurt width={size} height={size} />;
    case CurrencyIconKey.AAVE:
      return ({ size }) => <Aave width={size} height={size} />;
    case CurrencyIconKey.YFI:
      return ({ size }) => <Yfi width={size} height={size} />;
    case CurrencyIconKey.COMP:
      return ({ size }) => <Comp width={size} height={size} />;
    case CurrencyIconKey.DYDX:
      return ({ size }) => <Dydx width={size} height={size} />;
    case CurrencyIconKey.AXS:
      return ({ size }) => <Axs width={size} height={size} />;
    case CurrencyIconKey.ENJ:
      return ({ size }) => <Enj width={size} height={size} />;
    case CurrencyIconKey.GNO:
      return ({ size }) => <Gno width={size} height={size} />;
    case CurrencyIconKey.LRC:
      return ({ size }) => <Lrc width={size} height={size} />;
    case CurrencyIconKey.BAT:
      return ({ size }) => <Bat width={size} height={size} />;
    case CurrencyIconKey.BNT:
      return ({ size }) => <Bnt width={size} height={size} />;
    case CurrencyIconKey.ZRX:
      return ({ size }) => <Zrx width={size} height={size} />;
    case CurrencyIconKey.ONEINCH:
      return ({ size }) => <OneInch width={size} height={size} />;
    case CurrencyIconKey.BMD:
      return ({ size }) => <Bmd width={size} height={size} />;
    case CurrencyIconKey.HKD:
      return ({ size }) => <Hkd width={size} height={size} />;
    case CurrencyIconKey.BNB_ERC20:
      return ({ size }) => <Bnb width={size} height={size} />;
    case CurrencyIconKey.EURC_ERC20:
      return ({ size }) => <Eurc width={size} height={size} />;
    case CurrencyIconKey.EURC_POLYGON:
      return ({ size }) => <Eurc width={size} height={size} />;
    case CurrencyIconKey.EURR_ERC20:
      return ({ size }) => <Eurr width={size} height={size} />;
    case CurrencyIconKey.PYTH_SPL:
      return ({ size }) => <Pyth width={size} height={size} />;
    case CurrencyIconKey.SOL:
      return ({ size }) => <Sol width={size} height={size} />;
    case CurrencyIconKey.BONK_SPL:
      return ({ size }) => <Bonk width={size} height={size} />;
    case CurrencyIconKey.WUSDM_ERC20:
      return ({ size }) => <Wusdm width={size} height={size} />;
    case CurrencyIconKey.SUSDE_ERC20:
      return ({ size }) => <Susde width={size} height={size} />;
    case CurrencyIconKey.SHIB_ERC20:
      return ({ size }) => <Shib width={size} height={size} />;
    case CurrencyIconKey.LDO_ERC20:
      return ({ size }) => <Ldo width={size} height={size} />;
    case CurrencyIconKey.RNDR_ERC20:
      return ({ size }) => <Rndr width={size} height={size} />;
    case CurrencyIconKey.RENDER_SOL:
      return ({ size }) => <Rndr width={size} height={size} />;
    case CurrencyIconKey.ENA_ERC20:
      return ({ size }) => <Ena width={size} height={size} />;
    case CurrencyIconKey.POLS_ERC20:
      return ({ size }) => <Pols width={size} height={size} />;
    case CurrencyIconKey.ERN_ERC20:
      return ({ size }) => <Ern width={size} height={size} />;
    case CurrencyIconKey.MYTH_ERC20:
      return ({ size }) => <Myth width={size} height={size} />;
    case CurrencyIconKey.FASTSP_POLYGON:
      return ({ size }) => <Fastsp width={size} height={size} />;
    case CurrencyIconKey.NAYM_BASE:
      return ({ size }) => <Naym width={size} height={size} />;
    case CurrencyIconKey.ETH_BASE:
      return ({ size }) => <EthBase width={size} height={size} />;
  }

  if (new RegExp(CurrencyIconKey.BRMD_).test(key)) {
    return ({ size }) => <BermudAir width={size} height={size} />;
  }

  if (new RegExp(CurrencyIconKey.AGRO_).test(key)) {
    return ({ size }) => <Agrorefiner width={size} height={size} />;
  }

  return ({ size }) => <Fallback width={size} height={size} />;
};

export const CurrencyIcon = (props: CurrencyIconProps) => {
  /**
   * Vars
   */
  const {
    currencyCode,
    size = 30,
    showLabel = true,
    labelTextSize = 'sm',
    labelCls,
    currencyName,
    apy,
    customLabel,
  } = props;

  /**
   * Hooks
   */
  const ccyInfo = useCurrencyInfoContext(currencyCode);

  /**
   * DOM
   */
  if (!currencyCode) {
    return null;
  }

  const ccyCode = currencyCode.toUpperCase() as CurrencyIconKey;
  const iconFn = svgIconMap(ccyCode);
  const icon = iconFn
    ? iconFn(props)
    : svgIconMap(CurrencyIconKey.FALLBACK)(props);
  return (
    <span
      data-testid="currency-icon"
      key={ccyCode}
      className="flex items-center"
    >
      <span
        className={cx(styles.component, {
          'bg-grey-bright rounded-full flex': !icon,
        })}
        style={{
          height: `${size}px`,
          width: `${size}px`,
          flexShrink: 0,
        }}
      >
        {icon && <Fragment>{icon}</Fragment>}
      </span>
      {showLabel || currencyName || customLabel ? (
        <div className="grid ml-3 gap-0.5 text-left">
          {showLabel && (
            <Fragment>
              <span
                className={cx(
                  `text-${labelTextSize} font-bold text-primary flex items-center gap-1.5`,
                  labelCls
                )}
              >
                <span>{customLabel || ccyInfo?.displayCode || ccyCode}</span>
                <Badge.EarnApy apy={apy} size="small" />
              </span>
            </Fragment>
          )}
          {currencyName && (
            <span className="text-xs font-normal text-grey-darker">
              {currencyName}
            </span>
          )}
        </div>
      ) : null}
    </span>
  );
};
