import { jsx as _jsx } from "react/jsx-runtime";
import { Platform, StyleSheet, Text } from 'react-native';
import { theme } from '@xbto/design-system';
import { shorthandToFontProps } from '../../utils';
var Variants;
(function (Variants) {
    Variants["body1"] = "body1";
    Variants["body2"] = "body2";
    Variants["heading1"] = "heading1";
})(Variants || (Variants = {}));
const FontFamilies = {
    InterWeb: { fontFamily: 'Inter' },
    InterRegular: { fontFamily: 'Inter-Regular' },
    InterBold: { fontFamily: 'Inter-Bold' },
};
export const PlatformsFontRegular = Platform.select({
    android: FontFamilies.InterRegular,
    ios: FontFamilies.InterRegular,
    web: FontFamilies.InterWeb,
});
export const PlatformsFontBold = Platform.select({
    android: FontFamilies.InterBold,
    ios: FontFamilies.InterBold,
    web: FontFamilies.InterWeb,
});
const $ = StyleSheet.create({
    textBase: Object.assign(Object.assign(Object.assign({}, shorthandToFontProps(theme.Body.Base.Regular)), { color: theme.color.text.primary }), PlatformsFontRegular),
    textBaseBold: Object.assign(Object.assign(Object.assign({}, shorthandToFontProps(theme.Body.Base.Bold)), { color: theme.color.text.primary }), PlatformsFontBold),
    textMedium: Object.assign(Object.assign(Object.assign({}, shorthandToFontProps(theme.Body.Medium.Regular)), { color: theme.color.text.primary }), PlatformsFontRegular),
    textMediumBold: Object.assign(Object.assign(Object.assign({}, shorthandToFontProps(theme.Body.Medium.Regular)), { color: theme.color.text.primary }), PlatformsFontBold),
    textHeading1: Object.assign(Object.assign(Object.assign({}, shorthandToFontProps(theme.Heading['1'])), { color: theme.color.text.primary }), PlatformsFontBold),
});
const StyleVariants = {
    true: {
        [Variants.body1]: $.textBase,
        [Variants.body2]: $.textMedium,
        [Variants.heading1]: $.textHeading1,
    },
    false: {
        [Variants.body1]: $.textBaseBold,
        [Variants.body2]: $.textMediumBold,
        [Variants.heading1]: $.textHeading1,
    },
};
/**
 * @deprecated - TODO(@benoit) - this can be deleted?
 * @param param0
 * @returns
 */
export const Typography = ({ children, variant, bold }) => {
    let styles = StyleVariants[`${!!bold}`][variant];
    return _jsx(Text, Object.assign({ style: styles }, { children: children }));
};
