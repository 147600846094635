import { FC, useCallback } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import {
  EnrichedCryptoAddress,
  SearchableModal,
  addressBookContentTemplate,
} from 'common';

export const AddressBook: FC<
  Partial<StepWizardChildProps> & {
    addresses: EnrichedCryptoAddress[];
    onCancel: () => void;
    onChangeAddress: (address: EnrichedCryptoAddress) => void;
  }
> = ({ addresses, onCancel, onChangeAddress, previousStep }) => {
  /**
   * Hooks
   */
  const onCancelPreview = useCallback(() => {
    onCancel();
    previousStep?.();
  }, []);

  const handleItemSelection = useCallback((address: EnrichedCryptoAddress) => {
    onChangeAddress(address);

    previousStep?.();
  }, []);

  /**
   * DOM
   */

  return (
    <SearchableModal
      values={addresses}
      onClose={onCancelPreview}
      onItemSelection={handleItemSelection}
      // @ts-ignore
      contentTemplate={addressBookContentTemplate}
      defaultSearchFilter={['address', 'label', 'currencyCode']}
      title="Choose address"
      minHeight="min-h-450"
    />
  );
};
