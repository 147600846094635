import { API } from 'api';
import { Enriched } from '../../types';
import { format as formatDateFns, addMinutes } from 'date-fns';
import { DATE_FORMATS } from '../../constants';

function formatDate(date: Date, format: string, toUtc?: boolean) {
  if (toUtc) {
    return formatDateFns(addMinutes(date, date.getTimezoneOffset()), format);
  }
  return formatDateFns(date, format);
}

export function enrichListAssetDetails(
  assets: API.ListAssetDetailsResponse[]
): Enriched.MarketDataRow[] {
  const result: Enriched.MarketDataRow[] = [];

  for (const asset of assets) {
    if (
      !asset.code ||
      !asset.displayCode ||
      !asset.name ||
      !asset.type ||
      !asset.data
    ) {
      // TODO(@jey) - log to DD, so we can notify BE of a wrongly configured asset
      continue;
    }

    // handle Pusher sending string instead of Date
    const valueAsOf =
      typeof asset.valueAsOf === 'string'
        ? new Date(asset.valueAsOf)
        : asset.valueAsOf;

    result.push({
      apy: asset.apy,
      valueAsOf: valueAsOf
        ? 'Value as of ' + formatDate(valueAsOf, DATE_FORMATS.DD_MMM_YYYY, true)
        : '',
      code: asset.code,
      name: asset.name,
      displayCode: asset.displayCode,
      data: asset.data,
      type: asset.type,
      isTrending: asset.isTrending,
    });
  }

  return result;
}
