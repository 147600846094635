import { memo, PropsWithChildren } from 'react';
import { FormError } from './form-error';
import { FormLabel } from './form-label';

type Props = PropsWithChildren<{
  error?: string | false;
  label?: string;
  labelAddon?: JSX.Element;
}>;

export const FormControl = memo(
  ({ children, error, label, labelAddon }: Props) => {
    return (
      <div className="mt-6">
        {!!label && <FormLabel addon={labelAddon}>{label}</FormLabel>}
        {children}
        {!!error && <FormError>{error}</FormError>}
      </div>
    );
  }
);
