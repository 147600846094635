import React, { FC, FocusEventHandler } from 'react';
import cx from 'classnames';
import { Listbox } from '@headlessui/react';
import { textItemTemplate, textSelectedItemTemplate } from './templates';
import { AppIcon } from '../app-icon';
import { useAppDialog } from '../../contexts/app-dialog-context';
import { SearchableModal, SearchableModalProps } from '../searchable-modal';
export interface SelectorProps extends SearchableModalProps {
  cls?: string;
  name?: string;
  value: any;
  values: any[];
  loadingValues?: boolean | undefined;
  onChange: (newValue: any) => void;
  getSelectedItem?: (value?: any) => any;
  getSelectedItemTemplate?: (item?: any) => JSX.Element | null;
  messageWhenEmpty?: string;
  getItemTemplate?: (
    item: any,
    selected: boolean,
    active: boolean
  ) => JSX.Element | null;
  onBlur?: FocusEventHandler<HTMLButtonElement> | undefined;
  disabled?: boolean | undefined;
  asAddon?: boolean | undefined;
  asCustomSelect?: boolean | undefined;
  placeHolder?: string | undefined;
  handleOpen?: (open: boolean) => void;
  contentCls?: string;
  optionsCls?: string;
}

export const AppSelector: FC<SelectorProps> = ({
  cls,
  contentCls,
  optionsCls,
  value,
  values,
  loadingValues,
  name,
  onChange,
  getSelectedItem,
  getSelectedItemTemplate = textSelectedItemTemplate,
  getItemValue = i => i,
  getItemTemplate = textItemTemplate,
  onBlur,
  disabled,
  asAddon = false,
  messageWhenEmpty = 'No results',
  placeHolder,
  handleOpen,
  contentTemplate,
  headerTemplate,
}) => {
  /**
   * Hooks
   */
  const { showDialog, hideDialog } = useAppDialog();
  /**
   * DOM
   */
  const hasValues = !!(values && values.length > 0);
  const selectedItem = getSelectedItem ? getSelectedItem(value) : value;
  const onClose = arg => {
    hideDialog();
    onChange(arg);
  };

  const isCustomSelect = Boolean(contentTemplate || headerTemplate);

  return (
    <React.Fragment>
      {!!isCustomSelect && (
        <button
          disabled={disabled}
          onClick={() => {
            showDialog(
              <SearchableModal
                value={value}
                values={values}
                onClose={onClose}
                onItemSelection={onClose}
                headerTemplate={headerTemplate}
                contentTemplate={contentTemplate}
              />
            );
            if (handleOpen) handleOpen(true);
          }}
          className="flex flex-row justify-between items-center w-full full app-input-select border border-grey-bright rounded"
        >
          <div className="truncate text-secondary font-bold">
            {getSelectedItemTemplate(selectedItem, placeHolder)}
          </div>
          <AppIcon
            icon="chevron-down-dark"
            size={25}
            bg="bg-transparent"
            cls={cx('w-4 h-4 stroke-current-color ml-2', {
              'text-gray-400': !selectedItem,
            })}
          />
        </button>
      )}
      {!isCustomSelect && (
        <Listbox value={value} onChange={onChange} disabled={disabled}>
          {({ open }: { open: boolean }) => {
            if (asAddon && handleOpen) {
              handleOpen(open);
            }
            return (
              <div className="relative">
                <Listbox.Button
                  name={name}
                  onBlur={onBlur}
                  className={cx(cls, {
                    'border-figma-secondary': open && !asAddon,
                  })}
                >
                  <div
                    className={cx(
                      contentCls,
                      'flex flex-row justify-between items-center w-full',
                      {
                        'opacity-25': disabled,
                      }
                    )}
                  >
                    <div className="truncate text-secondary font-bold">
                      {getSelectedItemTemplate(selectedItem, placeHolder)}
                    </div>
                    <AppIcon
                      icon="chevron-down-dark"
                      size={25}
                      bg="bg-transparent"
                      cls={cx('w-4 h-4 stroke-current-color ml-2', {
                        'text-gray-400': !selectedItem,
                      })}
                    />
                  </div>
                </Listbox.Button>
                <Listbox.Options
                  className={cx(
                    'absolute min-w-sm z-20 w-full bg-white rounded-4 overflow-x-hidden overflow-y-auto max-h-60 shadow-xl outline-none focus:outline:none mt-1',
                    optionsCls,
                    'border border-grey-bright focus:border-grey-bright',
                    { 'mt-1/2': asAddon }
                  )}
                >
                  {!hasValues ? (
                    <Listbox.Option
                      className="select-none relative px-4 py-2 flex justify-between items-center text-gray-400"
                      value="null"
                      disabled
                    >
                      {loadingValues ? 'Loading...' : messageWhenEmpty}
                    </Listbox.Option>
                  ) : (
                    <React.Fragment>
                      {values?.map((item, itemIndex) => (
                        <Listbox.Option
                          key={itemIndex}
                          className={cx(
                            'cursor-pointer select-none relative border-b border-grey-bright hover-bg-grey-brighter',
                            {
                              'border-none': itemIndex === values.length - 1,
                            }
                          )}
                          value={getItemValue(item)}
                        >
                          {({
                            selected,
                            active,
                          }: {
                            selected: boolean;
                            active: boolean;
                          }) =>
                            getItemTemplate(
                              item,
                              selected,
                              active,
                              isCustomSelect
                            )
                          }
                        </Listbox.Option>
                      ))}
                    </React.Fragment>
                  )}
                </Listbox.Options>
              </div>
            );
          }}
        </Listbox>
      )}
    </React.Fragment>
  );
};
