import { FC, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getStorageKeys, useEnvironmentConfig, AppDialog } from 'common';
import { AppStore, DataStore } from '../../store';
import { WorkflowSend } from '../workflows/send';
import { DepositsDisabled } from '../dialogs/deposits-diabled';
import { YieldDisabled } from '../dialogs/yield-disabled';
import { ReferredDialog } from '../dialogs/referred';
import { ThanksForWaitingDialog } from '../dialogs/thanks-for-waiting';
import { AddCashWorkflow } from '../workflows/add-cash';
import { ReceiveWorkflow } from '../workflows/receive';
import { TradeWorkflow } from '../workflows/trade';
import { WorkflowWithdrawCash } from '../workflows/withdraw-cash';
import { DASHBOARD_PARAMS } from './types';
import { TransferWorkflow } from '../workflows/transfer';
import { FilterNavigation } from '../transactions/transactions-filters/filter-navigation';
import { useGetTransactions } from '../transactions/use-get-transactions';
import { TransactionSummary } from '../transactions/summary';
import { API } from 'api';

export const TransactionWorkflows: FC = () => {
  /**
   * Store
   */
  const accountDetail = DataStore.useStoreState(s => s.portfolio.accountDetail);
  const activity = DataStore.useStoreState(s => s.transactions.activity);
  const transaction = DataStore.useStoreState(s => s.transactions.transaction);
  const identity = DataStore.useStoreState(s => s.user.identity);
  const isKycVerified = DataStore.useStoreState(
    s => s.user.isKycVerified || false
  );
  const paginatedActivitiesFilters = DataStore.useStoreState(
    s => s.transactions.paginatedActivitiesFilters
  );

  const {
    getAccountSettings,
    resetStateAddCash,
    resetStateTrade,
    resetStateReceive,
    resetStateSend,
    resetStateWithdrawCash,
    setActivity,
  } = DataStore.useStoreActions(_ => ({
    getAccountSettings: _.settings.getAccountSettings,
    resetStateAddCash: _.addCash.resetState,
    resetStateReceive: _.receive.resetState,
    resetStateSend: _.send.resetState,
    resetStateWithdrawCash: _.withdrawCash.resetState,
    resetStateTrade: _.buySell.resetState,
    setActivity: _.transactions.setActivity,
  }));
  const dashboardSelectedDialogType = AppStore.useStoreState(
    s => s.dashboardSelectedDialogType
  );
  const { setDashboardSelectedAsset, setDashboardSelectedDialogType } =
    AppStore.useStoreActions(_ => ({
      setDashboardSelectedDialogType: _.setDashboardSelectedDialogType,
      setDashboardSelectedAsset: _.setDashboardSelectedAsset,
    }));

  /**
   * Vars
   */
  const { REACT_APP_BUILD_ENV_NAME } = useEnvironmentConfig();
  const { confirmReferredModal } = getStorageKeys(
    'web',
    REACT_APP_BUILD_ENV_NAME
  );
  const userIsReferred = !!identity?.referred;
  const shouldShowYieldPrompt = !!accountDetail?.settings?.showYieldPrompt;
  const canEarnYield = !!accountDetail?.settings?.canEarnYield;
  const hasDepositedEver = !!accountDetail?.settings?.hasDepositedEver;

  /**
   * Methods
   */
  const shouldShowReferralPopup = (
    hasAnyBalance: boolean,
    userIsReferred: boolean,
    canEarnYield: boolean
  ) => {
    const isConfirmReferred = localStorage.getItem(confirmReferredModal);
    return (
      !isConfirmReferred && !hasAnyBalance && userIsReferred && canEarnYield
    );
  };

  /**
   * Hooks
   */
  const { getTransactions } = useGetTransactions();
  const { action } = useParams();
  useEffect(() => {
    if (!isKycVerified) {
      // these modals do not apply to non-kyc users
      return;
    }
    if (shouldShowYieldPrompt) {
      setDashboardSelectedDialogType('THANKS_FOR_WAITING');
      return;
    }
    if (
      shouldShowReferralPopup(
        hasDepositedEver, // false
        userIsReferred,
        canEarnYield
      )
    ) {
      setDashboardSelectedDialogType('REFERRED');
      return;
    }
  }, [
    userIsReferred,
    hasDepositedEver,
    shouldShowYieldPrompt,
    isKycVerified,
    canEarnYield,
  ]);
  useEffect(() => {
    if (!isKycVerified) {
      return;
    }
    if (action === DASHBOARD_PARAMS.CHECKOUT_STATUS) {
      setDashboardSelectedDialogType('CHECKOUT_STATUS');
      return;
    }
    if (action === DASHBOARD_PARAMS.WELCOME_BONUS) {
      setDashboardSelectedDialogType('workflow-add-cash');
      return;
    }
  }, [action, isKycVerified]);
  useEffect(() => {
    // ensure to reset state when dialog is closed
    if (!setDashboardSelectedDialogType) {
      setDashboardSelectedAsset(null);
    }
  }, [setDashboardSelectedDialogType]);

  const handleOnTransactionSummaryClosed = useCallback(async () => {
    setActivity(null);
    /**@TODO -
     * removed for now as this makes multiple calls when closing dialog
     * added refresh but to activity instead
     */
    // await getTransactions(paginatedActivitiesFilters);
    setDashboardSelectedDialogType(null);
    return;
  }, [paginatedActivitiesFilters]);

  /**
   * DOM
   */
  return (
    <>
      {/* dialogs  */}
      {/* add cash dialog  */}
      <AppDialog
        isOpen={dashboardSelectedDialogType === 'workflow-add-cash'}
        onClose={() => {
          resetStateAddCash();
          setDashboardSelectedAsset(null);
          setDashboardSelectedDialogType(null);
        }}
      >
        <AddCashWorkflow />
      </AppDialog>
      {/* receive dialog  */}
      <AppDialog
        isOpen={dashboardSelectedDialogType === 'workflow-receive'}
        showClose={false}
        onClose={() => {
          resetStateReceive();
          setDashboardSelectedAsset(null);
          setDashboardSelectedDialogType(null);
        }}
      >
        <ReceiveWorkflow
          onClose={() => {
            resetStateReceive();
            setDashboardSelectedAsset(null);
            setDashboardSelectedDialogType(null);
          }}
        />
      </AppDialog>
      {/* send dialog  */}
      <AppDialog
        buttonCls="top-9"
        isOpen={dashboardSelectedDialogType === 'workflow-send'}
        onClose={() => {
          resetStateSend();
          setDashboardSelectedAsset(null);
          setDashboardSelectedDialogType(null);
        }}
      >
        <WorkflowSend />
      </AppDialog>
      {/* withdraw cash dialog  */}
      <AppDialog
        isOpen={dashboardSelectedDialogType === 'workflow-withdraw-cash'}
        onClose={() => {
          resetStateWithdrawCash();
          setDashboardSelectedAsset(null);
          setDashboardSelectedDialogType(null);
        }}
      >
        <WorkflowWithdrawCash />
      </AppDialog>
      {/* trade - buy dialog  */}
      <AppDialog
        isOpen={
          dashboardSelectedDialogType === 'workflow-buy' ||
          dashboardSelectedDialogType === 'workflow-sell'
        }
        onClose={() => {
          resetStateTrade();
          setDashboardSelectedAsset(null);
          setDashboardSelectedDialogType(null);
        }}
      >
        <TradeWorkflow
          side={
            dashboardSelectedDialogType === 'workflow-buy'
              ? API.Side.Buy
              : API.Side.Sell
          }
        />
      </AppDialog>
      {/* dialog: transfer between accounts  */}
      <AppDialog
        isOpen={dashboardSelectedDialogType === 'workflow-transfer'}
        onClose={() => {
          setDashboardSelectedAsset(null);
          setDashboardSelectedDialogType(null);
          setDashboardSelectedDialogType(null);
        }}
      >
        <TransferWorkflow />
      </AppDialog>
      {/* deposit disabled warning  */}
      <AppDialog
        isOpen={dashboardSelectedDialogType === 'DEPOSITS_DISABLED'}
        onClose={() => setDashboardSelectedDialogType(null)}
      >
        <DepositsDisabled
          onClose={() => setDashboardSelectedDialogType(null)}
        />
      </AppDialog>
      {/* yield disabled warning  */}
      <AppDialog
        isOpen={dashboardSelectedDialogType === 'YIELD_DISABLED'}
        onClose={() => setDashboardSelectedDialogType(null)}
      >
        <YieldDisabled onClose={() => setDashboardSelectedDialogType(null)} />
      </AppDialog>
      {/* referred modal  */}
      <AppDialog
        isOpen={dashboardSelectedDialogType === 'REFERRED'}
        onClose={() => {
          setDashboardSelectedDialogType(null);
        }}
      >
        <ReferredDialog
          onActionClick={val => setDashboardSelectedDialogType(val)}
        />
      </AppDialog>
      {/* thanks-for-waiting modal  */}
      <AppDialog
        showClose={false}
        isOpen={dashboardSelectedDialogType === 'THANKS_FOR_WAITING'}
        onClose={() => {
          if (
            shouldShowReferralPopup(
              hasDepositedEver,
              userIsReferred,
              canEarnYield
            )
          ) {
            setDashboardSelectedDialogType('REFERRED');
            return;
          }
          setDashboardSelectedDialogType(null);
        }}
      >
        <ThanksForWaitingDialog
          onActionClick={async val => {
            await getAccountSettings({
              isBackgroundXHR: false,
            });
            if (
              shouldShowReferralPopup(
                hasDepositedEver,
                userIsReferred,
                canEarnYield
              )
            ) {
              setDashboardSelectedDialogType('REFERRED');
              return;
            }
            setDashboardSelectedDialogType(val);
          }}
        />
      </AppDialog>

      {/* dialog: transactions - activity filters */}
      <AppDialog
        showClose={false}
        isOpen={dashboardSelectedDialogType === 'transactions-activity-filters'}
        onClose={() => setDashboardSelectedDialogType(null)}
      >
        <FilterNavigation
          onClose={() => setDashboardSelectedDialogType(null)}
          getTransactions={getTransactions}
        />
      </AppDialog>

      {/* dialog: transaction - summary  */}
      <AppDialog
        isOpen={dashboardSelectedDialogType === 'transactions-activity-summary'}
        onClose={handleOnTransactionSummaryClosed}
      >
        <TransactionSummary
          activity={activity}
          transaction={transaction}
          onClose={handleOnTransactionSummaryClosed}
        />
      </AppDialog>
    </>
  );
};
