import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { Platform, TextInput } from 'react-native';
import { colors, createStyles } from '../../theme';
import { Row } from '../row';
import { fontBoldStyle, fontMediumStyle } from '../text';
import { useInputFocusManager } from '../../hooks';
export const Input = ({ value, onValueChange, maxLength = 256, placeholder = 'Search', style = undefined, }) => {
    /**
     * Hooks
     */
    const { isFocused, onFocus, onBlur } = useInputFocusManager();
    /**
     * Methods
     */
    const handleOnChange = useCallback((newValue) => {
        if (!onValueChange) {
            return;
        }
        if (newValue === value) {
            return;
        }
        onValueChange(newValue);
    }, [value, onValueChange]);
    /**
     * DOM
     */
    const computedInputStyles = useMemo(() => {
        const styles = [$.textInput];
        if (isFocused) {
            styles.push({
                // @ts-expect-error - web specific property
                outline: 'none',
            });
        }
        if (!!(value === null || value === void 0 ? void 0 : value.length)) {
            styles.push($.value);
        }
        else {
            styles.push($.placeholder);
        }
        return styles;
    }, [isFocused, value]);
    return (_jsx(Row.L, Object.assign({ px: "s", py: "xs", style: [
            $.inputContainer,
            style,
            isFocused && $.focused,
            // TODO(Hadrien): Manage disabled && inputStyles.disabled,
            // TODO(Hadrien): Manage error && inputStyles.error,
        ] }, { children: _jsx(TextInput, { style: computedInputStyles, value: value, onChangeText: handleOnChange, maxLength: maxLength, placeholder: placeholder, keyboardType: "web-search", returnKeyType: "search", allowFontScaling: false, autoCapitalize: "none", autoCorrect: false, keyboardAppearance: "dark", numberOfLines: 1, 
            // TODO(@benoit/ @hadrien): once we have tokens
            placeholderTextColor: "#B8B8B8" // theme.color.text.placeholder
            , selectionColor: colors.onTextInputEm, underlineColorAndroid: colors.transparent, onBlur: onBlur, onFocus: onFocus }) })));
};
// TODO: use theme / design token
const $ = createStyles({
    inputContainer: {
        justifyContent: 'center',
        borderRadius: 'rad1',
        backgroundColor: 'surfaceAlt',
        borderColor: 'outline',
        borderWidth: 1,
        maxHeight: Platform.select({ web: 52, default: 48 }),
        paddingVertical: Platform.select({ web: 16, default: 12 }),
        paddingHorizontal: Platform.select({ web: 16, default: 14 }),
    },
    textInput: {
        fontSize: Platform.select({ web: 14, default: 12 }),
        lineHeight: Platform.select({ web: 20, default: 16 }),
        height: 20,
        color: 'onTextInput',
        flexGrow: 1,
        backgroundColor: 'surfaceAlt',
        borderColor: 'transparent',
        borderRadius: 'rad1',
        // @ts-ignore - web specific property
        outline: 'none',
    },
    focused: {
        borderColor: 'primary',
    },
    value: Object.assign({}, fontMediumStyle),
    placeholder: Object.assign({}, fontBoldStyle),
});
