var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { StyleSheet } from 'react-native';
import { colors } from '../../theme';
import { Col } from '../../atoms/col';
import { Icon } from '../../molecules/icon';
export const BadgeIcon = memo((_a) => {
    var { color: colorName = 'surfaceAccentActive', iconColor: iconColorName, size = 'md' } = _a, rest = __rest(_a, ["color", "iconColor", "size"]);
    const backgroundColor = colors[colorName];
    const isDark = colorName === 'primary' ||
        colorName === 'error' ||
        colorName === 'success';
    // || (colorName === 'accent' && rest.name === 'logo'); // TODO(Hadrien): Leaving logo for later
    const iconColor = iconColorName !== null && iconColorName !== void 0 ? iconColorName : (isDark ? 'onSurfaceInv' : 'onSurfaceEm');
    return (_jsx(Col.C, Object.assign({ style: [
            $.container,
            { backgroundColor },
            size === 'lg' && $.large,
            size === 'md' && $.medium,
            size === 'sm' && $.small,
            size === 'xs' && $.xsmall,
        ] }, { children: _jsx(Icon, Object.assign({}, rest, { size: size, color: iconColor })) })));
});
const $ = StyleSheet.create({
    container: {
        height: 48,
        width: 48,
        borderRadius: 24,
    },
    xsmall: {
        height: 24,
        width: 24,
        borderRadius: 12,
    },
    small: {
        height: 32,
        width: 32,
        borderRadius: 16,
    },
    medium: {
        height: 36,
        width: 36,
        borderRadius: 18,
    },
    large: {
        height: 80,
        width: 80,
        borderRadius: 40,
    },
});
