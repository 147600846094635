import { FC, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../routes';
import { OnboardingLayout } from '../components/layouts/onboarding';
import { useEffect } from 'react';
import { API_ERROR_CODES } from 'common';
import { DataStore } from '../store';
import { appStorage } from '../utils/app-storage';
import { TwoFaWidget } from '~/components/two-fa-widget';
import { FormFooter } from '~/components/workflows/shared/form-footer';

export const Login2fa: FC = () => {
  /**
   * Store
   */
  const error = DataStore.useStoreState(s => s.error);
  const twoFaToken = DataStore.useStoreState(a => a.user.twoFaToken);
  const completeChallenge = DataStore.useStoreActions(
    a => a.user.completeChallenge
  );

  /**
   * Hooks
   */
  const navigate = useNavigate();
  const [twoFaCode, setTwoFaCode] = useState('');

  /**
   * Methods
   */
  const onFormSubmit = async () => {
    if (!twoFaToken) {
      // expired or invalid token; redirect to sign in
      navigate(APP_ROUTES.NON_AUTH_HOME);
      return;
    }

    const res = await completeChallenge({
      token: twoFaToken,
      code: twoFaCode || '',
    });

    if (!res?.isSuccessful) {
      if (res?.errorCode === API_ERROR_CODES.USER_ACOUNT_LOCKED) {
        navigate(APP_ROUTES.NON_AUTH_ACCOUNT_LOCKED);
        return;
      }
    }

    if (res?.result?.jwt) {
      appStorage.jwt.set(res.result.jwt);
    }
  };

  useEffect(() => {
    if (!twoFaToken) {
      navigate(APP_ROUTES.NON_AUTH_HOME);
    }
  }, [twoFaToken]);

  /**
   * DOM
   */
  return (
    <OnboardingLayout testid="login-2fa-page" userIcon={false}>
      <div className="max-w-600 w-full bg-white rounded-4 text-primary min-h-350 flex flex-col justify-between pt-10">
        <div className="px-6 sm:px-10 text-center">
          <TwoFaWidget
            askFor2FA
            isEnabled
            onChange={code => setTwoFaCode(code)}
            error={error}
          />
        </div>
        <FormFooter
          onCancel={() => navigate(APP_ROUTES.NON_AUTH_HOME)}
          onSubmit={onFormSubmit}
          isSubmitDisabled={!twoFaCode}
          submitText="Verify"
          submitCls="app-button-accent"
        />
      </div>
      <Link to={APP_ROUTES.NON_AUTH_HOME} className="text-white mt-8">
        Back to <b>sign in</b>
      </Link>
    </OnboardingLayout>
  );
};
