import { useCallback, useState } from 'react';
import { Platform } from 'react-native';
export const useInputFocusManager = () => {
    const [isFocused, setIsFocused] = useState(false);
    const onFocus = useCallback(() => {
        if (Platform.OS !== 'web') {
            return;
        }
        setIsFocused(true);
    }, [setIsFocused]);
    const onBlur = useCallback(() => {
        if (Platform.OS !== 'web') {
            return;
        }
        setIsFocused(false);
    }, [setIsFocused]);
    return {
        isFocused,
        onFocus,
        onBlur,
    };
};
