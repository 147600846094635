import { jsx as _jsx } from "react/jsx-runtime";
import { createStyles } from '../../theme';
import { Col } from '../col';
import { TextCaption } from '../text';
export const Label = ({ small = false, title }) => {
    return (_jsx(Col.C, Object.assign({ style: [$.container, small && $.containerSmall] }, { children: _jsx(TextCaption, Object.assign({ em: true, color: "onInfoSurface" }, { children: title })) })));
};
const $ = createStyles({
    container: {
        backgroundColor: 'infoSurface',
        borderRadius: 999,
        p: 'xs',
    },
    containerSmall: {
        py: 'xxs',
        paddingHorizontal: 6,
    },
});
