import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useRef, useState } from 'react';
import { Keyboard, Platform, Pressable, TextInput, View, } from 'react-native';
import { shift, useFloating } from '@floating-ui/react-native';
import { colors, createStyles } from '../../theme';
import { drop2 } from '../../utils/box-shadow';
import { Col, fontBoldStyle, fontMediumStyle, Row, TextBody, } from '../../atoms';
export const SearchSuggestInput = ({ maxLength, onChange, style, value, suggestions, }) => {
    const { refs, floatingStyles } = useFloating({
        middleware: [shift()],
    });
    /**
     * State
     */
    const [isFocused, setIsFocused] = useState(false);
    const [suggestionsOpen, setSuggestionsOpen] = useState(false);
    /**
     * Methods
     */
    const onFocus = useCallback(() => {
        setSuggestionsOpen(true);
        if (Platform.OS !== 'web') {
            return;
        }
        setIsFocused(true);
    }, [setIsFocused]);
    const timeout = useRef();
    const onBlur = useCallback(() => {
        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => setSuggestionsOpen(false), 200);
        if (Platform.OS !== 'web') {
            return;
        }
        setIsFocused(false);
    }, [setIsFocused]);
    const onSelectSuggestion = useCallback((suggestion) => () => {
        onChange === null || onChange === void 0 ? void 0 : onChange(suggestion.label);
        Keyboard.dismiss();
    }, [onChange]);
    /**
     * DOM
     */
    const computedInputStyles = useMemo(() => {
        const styles = [$.textInput];
        if (isFocused) {
            styles.push({
                // @ts-expect-error this property is web specific
                outline: 'none',
            });
        }
        if (!!(value === null || value === void 0 ? void 0 : value.length)) {
            styles.push($.value);
        }
        else {
            styles.push($.placeholder);
        }
        return styles;
    }, [isFocused, value]);
    return (_jsx(View, Object.assign({ ref: refs.setReference, collapsable: false }, { children: _jsxs(Col, Object.assign({ style: $.suggestionContainer }, { children: [_jsx(Row.L, Object.assign({ px: "s", py: "xs", style: [
                        $.inputContainer,
                        style,
                        isFocused && $.focused,
                        // TODO(Hadrien): Manage disabled && inputStyles.disabled,
                        // TODO(Hadrien): Manage error && inputStyles.error,
                    ] }, { children: _jsx(TextInput, { style: computedInputStyles, value: value, onChangeText: onChange, placeholder: "Search", keyboardType: "web-search", returnKeyType: "search", allowFontScaling: false, autoCapitalize: "none", autoCorrect: false, maxLength: maxLength, keyboardAppearance: "dark", numberOfLines: 1, placeholderTextColor: "#B8B8B8" // theme.color.text.placeholder
                        , selectionColor: colors.onTextInputEm, underlineColorAndroid: colors.transparent, onBlur: onBlur, onFocus: onFocus }) })), suggestionsOpen && (_jsx(View, Object.assign({ ref: refs.setOffsetParent, collapsable: false }, { children: _jsx(View, Object.assign({ ref: refs.setFloating, collapsable: false, style: [floatingStyles, $.suggestions] }, { children: suggestions === null || suggestions === void 0 ? void 0 : suggestions.map(suggestion => (_jsx(Pressable, Object.assign({ onPress: onSelectSuggestion(suggestion) }, { children: _jsx(TextBody, Object.assign({ color: "onTextInput" }, { children: suggestion.label })) }), suggestion.id))) })) })))] })) })));
};
// TODO: use theme / design token
const $ = createStyles({
    inputContainer: {
        justifyContent: 'center',
        borderRadius: 'rad1',
        backgroundColor: 'surfaceAlt',
        borderColor: 'outline',
        borderWidth: 1,
        maxHeight: Platform.select({ web: 52, default: 48 }),
        paddingVertical: Platform.select({ web: 16, default: 12 }),
        paddingHorizontal: Platform.select({ web: 16, default: 14 }),
    },
    textInput: {
        fontSize: Platform.select({ web: 14, default: 12 }),
        lineHeight: Platform.select({ web: 20, default: 16 }),
        height: 20,
        color: 'onTextInput',
        flexGrow: 1,
        backgroundColor: 'surfaceAlt',
        borderColor: 'transparent',
        borderRadius: 'rad1',
    },
    focused: {
        borderColor: 'primary',
    },
    value: Object.assign({}, fontMediumStyle),
    placeholder: Object.assign({}, fontBoldStyle),
    suggestionContainer: { position: 'relative' },
    suggestions: Object.assign({ width: '100%', rowGap: 8, marginVertical: 2, padding: 16, backgroundColor: 'onSurfaceInv', borderRadius: 'rad1', borderColor: 'outline', borderWidth: 1 }, drop2),
});
