import {
  AppTenant,
  ConfigContextProvider,
  FallbackConfigContextProvider,
  useMaintenanceInterval,
} from 'common';
import { FC, useEffect, useState } from 'react';
import { useQuery } from '../hooks/use-query';
import { AppStore, DataStore } from '../store';
import { AppHydrator } from './hydrator';
import { loadSystemConfig, setComputedConfig } from 'common';
import { getInitialConfig } from '../api/api-target';
import { apiClient } from '../api/client';
import { BrowserRouter } from 'react-router-dom';
import { UnrestrictedMaintenance } from '../components/404/unrestricted-maintenance';
import { appStorage } from '~/utils/app-storage';
import { AppLoader } from '~/components/app-loader';

const Bootstrapper: FC = () => {
  /**
   * Store
   */
  const setTenant = DataStore.useStoreActions(_ => _.setTenant);

  /**
   * State
   */
  const [ready, setReady] = useState(false);

  /**
   * Hooks
   */
  const query = useQuery();
  const { isMaintenance, setMaintenance } = useMaintenanceInterval(apiClient);

  useEffect(() => {
    if (!isMaintenance) {
      (async function () {
        if (!query) {
          return;
        }
        const initialConfig = await getInitialConfig();
        const url = initialConfig.apiTarget || process.env.REACT_APP_API_URL;
        const tenant = (query.get('tenant') ||
          initialConfig.tenant ||
          process.env.REACT_APP_TENANT!) as AppTenant;

        apiClient.setBaseUrl(url!);

        try {
          await loadSystemConfig(apiClient);
          setComputedConfig({
            apiUrl: url!,
            tenant,
          });

          // Note: this is necessary for re-use in `client.ts`
          appStorage.tenant.set(tenant);
          setTenant(tenant);
          setReady(true);
        } catch (e) {
          if ((e as { error: string }).error === 'login_required') {
            // This is a bug on Auth0.
            // If you have been logged out for a while,
            // isAuthenticated will return true, but getTokenSilently will fail with this error.
            // In this case, we simply have to reload the page for this to work.

            console.warn('Auth0 threw with ', e);
            document.location.reload();
            return;
          }
          console.warn('Failed to load system config', e);
          console.error('System config', e, JSON.stringify(e));
          setMaintenance(true);

          return;
        }
      })();
    }
  }, [isMaintenance]);

  useEffect(() => {
    window.addEventListener(
      'message',
      event => {
        // do not trust message sent from other origins
        if (event.origin !== location.origin) {
          return;
        }
        if (event.data === 'application-unavailable') {
          setMaintenance(true);
        }
      },
      false
    );
  });

  /**
   * DOM
   */

  if (isMaintenance) {
    return (
      <FallbackConfigContextProvider>
        <BrowserRouter>
          <UnrestrictedMaintenance />
        </BrowserRouter>
      </FallbackConfigContextProvider>
    );
  }

  if (!ready) {
    return <AppLoader spinnerTop="35%" />;
  }

  return (
    <ConfigContextProvider>
      <BrowserRouter>
        <AppHydrator />
      </BrowserRouter>
    </ConfigContextProvider>
  );
};

export const AppBootstrapper: FC = () => {
  /**
   * DOM
   */
  return (
    <DataStore.Provider>
      <AppStore.Provider>
        <Bootstrapper />
      </AppStore.Provider>
    </DataStore.Provider>
  );
};
