var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { AmountChangePercent } from '../amount-change-percent';
import { Row } from '../../atoms/row';
import { TextBody } from '../../atoms/text';
import { createStyles } from '../../theme';
export const AmountRoR = memo((_a) => {
    var { type } = _a, rest = __rest(_a, ["type"]);
    return (_jsxs(Row.C, Object.assign({ style: $.container }, { children: [_jsx(AmountChangePercent, Object.assign({}, rest)), _jsx(TextBody, { children: type.toUpperCase() })] })));
});
const $ = createStyles({
    container: {
        columnGap: 2,
    },
});
