export type FormErrors<Form extends object> = Partial<
  Record<keyof Form, string>
>;

type FormValuesToBoolean<Form extends object> = Partial<
  Record<keyof Form, boolean>
>;

export type FormValuesTouched<Form extends object> = FormValuesToBoolean<Form>;

type FormValuesChanged<Form extends object> = FormValuesToBoolean<Form>;

export function compareFormValues<Form extends object>(_old: Form, _new: Form) {
  const result: FormValuesChanged<Form> = {};

  for (const key in _new) {
    const oldValue = _old[key];
    const newValue = _new[key];

    result[key] = oldValue !== newValue;
  }

  return result;
}
