import { appStorage } from '../utils/app-storage';
import { APP_ROUTES, externalRoutes } from '../routes';
import {
  ApiError,
  ApiResponse,
  CRITICAL_ENDPOINTS,
  ERROR_MESSAGES,
  decodeIdentity,
} from 'common';
import { API } from 'api';
import { Auth0 } from '~/utils/auth0';

// This converts date strings into actual javascript Date objects at deserialization
const dateFormat =
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,7})?([Z+-]\d\d:\d\d)$/;

const handleResponse = async (response: Response) => {
  const text = await response.text();

  let error: ApiError | null = null;

  try {
    const data = JSON.parse(text) as ApiResponse<null>;

    let errorMessage;

    if (response.status === 401) {
      errorMessage = ERROR_MESSAGES.API_401;
    } else if (response.status === 403) {
      errorMessage = ERROR_MESSAGES.API_403;
    } else if (data.errorMessage) {
      errorMessage = data.errorMessage;
    } else {
      errorMessage = ERROR_MESSAGES.DEFAULT;
    }

    error = new ApiError(response.status, errorMessage, data.errorCode ?? -1);
  } catch (err) {
    // Parse error
    error = new ApiError(response.status, ERROR_MESSAGES.DEFAULT, -1);
  }

  throw error;
};

class WebClient extends API.StablehouseClient {
  protected async getAccessToken() {
    const auth0 = await Auth0.getClient();
    let accessToken: string | null = null;

    if (await auth0.isAuthenticated()) {
      accessToken = await auth0.getTokenSilently();
    } else {
      const identity = await decodeIdentity(appStorage.jwt.get());
      accessToken = identity?.token ?? null;
    }

    return accessToken;
  }
}

export const apiClient = new WebClient(
  window.apiTarget || process.env.REACT_APP_API_URL
);

apiClient.jsonParseReviver = (key, value) => {
  if (typeof value === 'string' && dateFormat.test(value)) {
    return new Date(value);
  }
  return value;
};

apiClient.onError = async (response: Response) => {
  if (response.status === 401) {
    // It's ok if the logout fails because we're already logged out from another tab
    if (response.url.endsWith('v1/authentication/logout')) {
      return handleResponse(response);
    }

    appStorage.jwt.remove();
    apiClient.setDefaultAccountId(null);
    // if unauthorized, clear authentication and redirect to the login page.

    if (
      externalRoutes
        .map(i => i.toString())
        .indexOf(document.location.pathname) === -1
    ) {
      // Only if we're on an authenticated route
      document.location.replace(APP_ROUTES.NON_AUTH_LOGGED_OUT);
    }
  }

  if (
    response.status === 429 &&
    document.location.href !== APP_ROUTES.NON_AUTH_429
  ) {
    document.location.replace(APP_ROUTES.NON_AUTH_429);
  }

  if (
    response.status >= 500 &&
    document.location.href !== APP_ROUTES.NON_AUTH_500x
  ) {
    const isCritical = CRITICAL_ENDPOINTS.find(i => i.test(response.url));
    if (isCritical) {
      window.postMessage('application-unavailable', location.origin);
    }
  }

  return handleResponse(response);
};
