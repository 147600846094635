import { getDataStore } from 'common';
import { createContextStore } from 'easy-peasy';
import { apiClient } from '../api/client';
import { appStorage } from '../utils/app-storage';
import { appModel } from './app-store';

export const DataStore = getDataStore(apiClient, appStorage);
export const AppStore = createContextStore(appModel, {
  name: 'App Store',
});
