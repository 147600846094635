var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { API } from 'api';
import { MARKETS_HEADERS } from './constants';
import { SortableHeaders } from '../../molecules/sortable-headers';
import { createStyles } from '../../theme';
import { Row } from '../../atoms/row';
const FIAT_HEADERS1 = [MARKETS_HEADERS.NAME];
const FIAT_HEADERS2 = [
    MARKETS_HEADERS.PRICE,
    MARKETS_HEADERS.PERCENTAGE_CHANGE,
];
const FUND_HEADERS1 = [MARKETS_HEADERS.FUND_NAME];
const FUND_HEADERS2 = [MARKETS_HEADERS.PERFORMANCE];
const BOND_HEADERS1 = [MARKETS_HEADERS.BOND_NAME];
const BOND_HEADERS2 = [MARKETS_HEADERS.YIELD];
const CRYPTO_HEADERS1 = [MARKETS_HEADERS.NAME, MARKETS_HEADERS.MARKET_CAP];
const CRYPTO_HEADERS2 = [
    MARKETS_HEADERS.PRICE,
    MARKETS_HEADERS.PERCENTAGE_CHANGE,
];
export function MarketListHeader(_a) {
    var { assetType } = _a, sortable = __rest(_a, ["assetType"]);
    let content = null;
    if (assetType === API.MarketAssetType.Fiat) {
        content = (_jsxs(_Fragment, { children: [_jsx(SortableHeaders, Object.assign({ items: FIAT_HEADERS1 }, sortable)), _jsx(SortableHeaders, { items: FIAT_HEADERS2 })] }));
    }
    if (assetType === API.MarketAssetType.Fund) {
        content = (_jsxs(_Fragment, { children: [_jsx(SortableHeaders, Object.assign({ items: FUND_HEADERS1 }, sortable)), _jsx(SortableHeaders, { items: FUND_HEADERS2 })] }));
    }
    if (assetType === API.MarketAssetType.Bond) {
        content = (_jsxs(_Fragment, { children: [_jsx(SortableHeaders, Object.assign({ items: BOND_HEADERS1 }, sortable)), _jsx(SortableHeaders, { items: BOND_HEADERS2 })] }));
    }
    if (assetType === API.MarketAssetType.Crypto) {
        content = (_jsxs(_Fragment, { children: [_jsx(SortableHeaders, Object.assign({ items: CRYPTO_HEADERS1 }, sortable)), _jsx(SortableHeaders, Object.assign({ items: CRYPTO_HEADERS2 }, sortable))] }));
    }
    return _jsx(Row.LR, Object.assign({ style: $.header }, { children: content }));
}
const $ = createStyles({
    header: {
        backgroundColor: 'surfaceAlt',
        px: 'm',
        py: 's',
        borderBottomWidth: 1,
        borderTopWidth: 1,
        borderColor: 'outline',
    },
});
