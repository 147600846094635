export enum StablehousePusherChannel {
  // user profile -> status's
  USER_EMAIL_VERIFICATION = `private-user-email-verification`,

  // meta data
  FX_RATES = `public-fx-rates`,
  MARKETS = `public-markets`,

  // user account -> updates
  ACCOUNT_DETAILS_STATUS = `private-account-details-status`,
  USER_BALANCE_ORGANIZATION = `private-user-balances-org`,
  WITHDRAWAL_STATUS = `private-withdrawal-status`,
  TRANSFER_STATUS = `private-transfer-status`,
  FIAT_WITHDRAWAL_STATUS = 'private-fiat-withdrawal-status',
}

enum StablehousePusherEvent {
  // user profile -> status's
  USER_EMAIL_VERIFICATION = `chunked-email-confirmed`,

  // meta data
  FX_RATES = `chunked-fx-rates`,
  MARKETS = `asset`,

  // user account -> updates
  ACCOUNT_DETAILS_STATUS = `private-account-details-status`,
  USER_BALANCE_ORGANIZATION = `chunked-compressed-user-balance-organization`,
  WITHDRAWAL_STATUS = `chunked-withdrawal-status`,
  TRANSFER_STATUS = `chunked-transfer-status`,
  FIAT_WITHDRAWAL_STATUS = 'chunked-fiat-withdrawal-status',
}

const getPusherChannelAndEvent = (
  channelName: StablehousePusherChannel,
  env: string,
  id = '', // could be - accountId or orgId
  suffix: string | null = ''
) => {
  const idAndSuffix = !!suffix && suffix.length > 0 ? `${id}-${suffix}` : id;
  switch (channelName) {
    // user profile -> status's
    case StablehousePusherChannel.USER_EMAIL_VERIFICATION:
      return {
        channel: `${StablehousePusherChannel.USER_EMAIL_VERIFICATION}-${idAndSuffix}-${env}`,
        event: StablehousePusherEvent.USER_EMAIL_VERIFICATION,
      };

    // meta data
    case StablehousePusherChannel.FX_RATES:
      return {
        channel: `${StablehousePusherChannel.FX_RATES}-${env}`,
        event: StablehousePusherEvent.FX_RATES,
      };
    case StablehousePusherChannel.MARKETS:
      return {
        channel: `${StablehousePusherChannel.MARKETS}-${env}-${idAndSuffix}`,
        event: StablehousePusherEvent.MARKETS,
      };

    // user account -> updates
    case StablehousePusherChannel.ACCOUNT_DETAILS_STATUS:
      return {
        channel: `${StablehousePusherChannel.ACCOUNT_DETAILS_STATUS}-${idAndSuffix}-${env}`,
        event: StablehousePusherEvent.ACCOUNT_DETAILS_STATUS,
      };
    case StablehousePusherChannel.USER_BALANCE_ORGANIZATION:
      return {
        channel: `${StablehousePusherChannel.USER_BALANCE_ORGANIZATION}-${idAndSuffix}-${env}`,
        event: StablehousePusherEvent.USER_BALANCE_ORGANIZATION,
      };
    case StablehousePusherChannel.WITHDRAWAL_STATUS:
      return {
        channel: `${StablehousePusherChannel.WITHDRAWAL_STATUS}-${idAndSuffix}-${env}`,
        event: StablehousePusherEvent.WITHDRAWAL_STATUS,
      };
    case StablehousePusherChannel.TRANSFER_STATUS:
      return {
        channel: `${StablehousePusherChannel.TRANSFER_STATUS}-${idAndSuffix}-${env}`,
        event: StablehousePusherEvent.TRANSFER_STATUS,
      };
    case StablehousePusherChannel.FIAT_WITHDRAWAL_STATUS:
      return {
        channel: `${StablehousePusherChannel.FIAT_WITHDRAWAL_STATUS}-${idAndSuffix}-${env}`,
        event: StablehousePusherEvent.FIAT_WITHDRAWAL_STATUS,
      };
  }
};

type ChunkMessage = {
  id: string;
  index: number;
  chunk: string;
  chunksCount: number;
};
type Message = ChunkMessage | string;

export const subscribePusher = <T>(
  pusher: any | null,
  channelName: StablehousePusherChannel,
  env: string,
  callback: (err: null | unknown, data?: T) => void,
  id = '',
  suffix?: string | null
) => {
  if (!pusher || !channelName) {
    console.error(`Cannot subscribe to pusher channel: ${channelName}`);
    return;
  }

  const { channel, event } = getPusherChannelAndEvent(
    channelName,
    env,
    id,
    suffix
  );

  const _chunks = {};

  pusher.subscribe(channel);
  console.info(`pusher >> ${channel} >> subscribed`);

  pusher.bind(event, (message: Message) => {
    try {
      if (!event.startsWith(`chunked-`)) {
        const data = message as string;
        const deserialized = typeof data === 'string' ? JSON.parse(data) : data;
        callback(null, deserialized);
      } else {
        const data = message as ChunkMessage;
        // eslint-disable-next-line
        if (!_chunks.hasOwnProperty(data.id)) {
          _chunks[data.id] = [];
        }

        const c = _chunks[data.id];
        c[data.index] = data;

        // when all chunks have been received
        if (
          c.length === data.chunksCount &&
          c.filter((i: any) => i).length === data.chunksCount
        ) {
          delete _chunks[data.id];
          const combined = c.map((i: any) => i.chunk).join('');
          const deserialized = JSON.parse(combined);
          callback(null, deserialized);
        }
      }
    } catch (error) {
      callback(error);
      throw error;
    }
  });

  const unsubscribe = () => {
    pusher.unbind(event, callback);
    pusher.unsubscribe(channel);
  };

  return unsubscribe;
};
