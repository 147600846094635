// This hooks take care of providing a method that both renews the token and saves it local storage.
// Saving the token to the local storage is not something that can be done in the common repo, as it is specific to the implementation: web/mobile
import { useEffect } from 'react';
import debounce from 'lodash.debounce';
import { useNavigate } from 'react-router-dom';

import { TIMERS } from 'common';
import { DataStore } from '../store';
import { appStorage } from '../utils/app-storage';
import { APP_ROUTES, externalRoutes } from '~/routes';
import { Auth0 } from '~/utils/auth0';

export const useRenewedToken = () => {
  const renewToken = DataStore.useStoreActions(a => a.user.renewToken);

  return async () => {
    const response = await renewToken();
    if (response.jwt) {
      appStorage.jwt.set(response.jwt);
    }
    return response.user;
  };
};

// On top of invalidating the token, we need to clear the local storage
export const useLogout = () => {
  const resetStore = DataStore.useStoreActions(a => a.resetStore);
  const legacyLogout = DataStore.useStoreActions(a => a.user.logout);
  const navigate = useNavigate();

  return async () => {
    const auth0Client = await Auth0.getClient();
    appStorage.jwt.remove();
    if (await auth0Client.isAuthenticated()) {
      await Auth0.logout();
      await resetStore();
    } else {
      await legacyLogout();
      navigate(APP_ROUTES.NON_AUTH_LOGGED_OUT, { replace: true });
    }
  };
};

export const useOnFocusAuthCheck = () => {
  const resetStore = DataStore.useStoreActions(a => a.resetStore);

  useEffect(() => {
    // Sometimes both events are triggered, so we debounce
    const authCheckDebounced = debounce(
      async () => {
        if (externalRoutes.includes(window.location.pathname as APP_ROUTES)) {
          return;
        }
        const auth0Client = await Auth0.getClient();
        if (await auth0Client.isAuthenticated()) {
          return;
        }
        appStorage.jwt.remove();
        await resetStore();
        await Auth0.logout();
      },
      TIMERS.AUTH_CHECK,
      { leading: true, trailing: false }
    );

    const authCheckVisible = () => {
      if (document.visibilityState === 'visible') {
        // ignore promise, we just care to check if we receive a 401 to logout the user
        authCheckDebounced();
      }
    };

    document.addEventListener('visibilitychange', authCheckVisible);
    window.addEventListener('focus', authCheckDebounced);

    return () => {
      document.removeEventListener('visibilitychange', authCheckVisible);
      window.removeEventListener('focus', authCheckDebounced);
    };
  }, []);
};
