import { FC, useCallback } from 'react';
import { useEffectOnce } from 'react-use';
import { AppLoader } from '~/components/app-loader';
import { SettingsAddressBook } from '~/components/settings/address-book';
import { AppStore, DataStore } from '~/store';

import { SettingsLayout, SettingsMenuIds } from './layout';

export const AddressBook: FC = () => {
  /**
   * Store
   */
  const defaultAccountId = DataStore.useStoreState(
    s => s.user.defaultAccountId
  );
  const withdrawalAddresses = DataStore.useStoreState(
    s => s.settings.cryptoAddresses.withdrawalAddresses
  );
  const isLoading = DataStore.useStoreState(
    s => s.settings.cryptoAddresses.busy
  );

  const getWithdrawalAddresses = DataStore.useStoreActions(
    a => a.settings.cryptoAddresses.getWithdrawalAddresses
  );
  const dashboardSelectedDialogType = AppStore.useStoreState(
    s => s.dashboardSelectedDialogType
  );

  /**
   * Methods
   */
  const handleGetWithdrawalAddresses = useCallback(() => {
    if (defaultAccountId) {
      getWithdrawalAddresses({ accountId: defaultAccountId });
    }
  }, [getWithdrawalAddresses, defaultAccountId]);

  /**
   * Effects
   */
  useEffectOnce(() => {
    handleGetWithdrawalAddresses();
  });

  /**
   * DOM
   */
  return (
    <SettingsLayout
      hasPaddings={false}
      menuId={SettingsMenuIds.CRYPTO_ADDRESSES}
    >
      {isLoading && !dashboardSelectedDialogType ? (
        <AppLoader spinnerTop="104px" />
      ) : (
        <SettingsAddressBook
          addresses={withdrawalAddresses}
          onAddNewAddress={handleGetWithdrawalAddresses}
        />
      )}
    </SettingsLayout>
  );
};
