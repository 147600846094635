import { API } from 'api';
import { action, Action, Computed, computed, thunk } from 'easy-peasy';
import { runApi, FilterProps } from '../../utils';
import { ApiThunk } from '../types';
import {
  EnrichedAccountStatement,
  EnrichedAccountStatementItems,
} from '../../types/enriched';
import { DataModel } from '../data-store';
import { BaseModel, createBaseModel } from '../base-store';
import { ApiError } from '../api-error';
import { ERROR_MESSAGES } from '../../constants';

const computePdfName = (
  item: API.AccountStatementItems,
  period: string | null
) => {
  const fallbackName = (
    item.fileName ||
    item.accountLabel ||
    'statement'
  ).replace('.pdf', '');
  const suffix = period ? period.replace(' ', '-') : Date.now();
  return `${fallbackName}-${suffix}`;
};

export interface StatementsModel extends BaseModel {
  // statements
  _list: API.AccountStatement[] | null;
  setList: Action<StatementsModel, API.AccountStatement[] | null>;
  getList: ApiThunk<
    StatementsModel,
    API.ListAccountStatementRequest,
    API.AccountStatement[]
  >;
  list: Computed<StatementsModel, EnrichedAccountStatement[] | null, DataModel>;

  // statement
  getStatement: ApiThunk<
    StatementsModel,
    {
      statementId: string;
      accountId: string;
    },
    API.GetStatementPdfResponse
  >;
  getLatestStatementPdf: ApiThunk<
    StatementsModel,
    { accountId: string; assetCode: string },
    {
      statement: EnrichedAccountStatementItems;
      pdf: API.GetStatementPdfResponse | null;
    }
  >;

  // filters
  filters: FilterProps | null;
  setFilters: Action<StatementsModel, FilterProps | null>;
}

export const statementsModel: StatementsModel = {
  ...createBaseModel(),

  // statements
  _list: null,
  setList: action((state, payload) => {
    state._list = payload;
  }),
  getList: thunk((actions, payload, helpers) => {
    return runApi(
      actions,
      helpers,
      () => {
        return helpers.injections.apiClient.accountStatementList(
          Object.assign(
            {
              startDate: null,
              endDate: null,
              accountId: helpers.getStoreState().user.defaultAccountId,
            },
            payload
          )
        );
      },
      result => {
        actions.setList(result);
      },
      {
        'x-account-id':
          payload.accountId || helpers.getStoreState().user.defaultAccountId,
      }
    );
  }),
  list: computed([s => s._list], _list => {
    if (!_list || !_list.length) {
      return null;
    }

    const accumulator: EnrichedAccountStatement[] = [];

    const enriched = _list.reduce((out, item) => {
      let statements: EnrichedAccountStatementItems[] | null = null;
      if (item.statements && item.statements.length > 0) {
        statements = item.statements.map(st => {
          const labelExtra =
            (st.accountType === 'Fund' &&
              st.currency?.name &&
              ` / ${st.currency.name}`) ||
            '';
          return {
            ...st,
            accountLabel: st.accountLabel + labelExtra,
            downloadFileName: computePdfName(st, item.periodName),
          };
        });
      }
      const value: EnrichedAccountStatement = {
        periodName: item.periodName,
        periodType: item.periodType,
        statements,
      };
      out.push(value);
      return out;
    }, accumulator);

    return enriched;
  }),

  // statement
  getStatement: thunk((actions, payload, helpers) => {
    return runApi(
      actions,
      helpers,
      () => {
        return helpers.injections.apiClient.getStatementPdf(
          payload.statementId
        );
      },
      result => result,
      {
        'x-account-id':
          payload.accountId || helpers.getStoreState().user.defaultAccountId,
      }
    );
  }),
  getLatestStatementPdf: thunk((actions, { accountId, assetCode }, helpers) => {
    return runApi(
      actions,
      helpers,
      async () => {
        const statementResponse =
          await helpers.injections.apiClient.accountStatementList({
            limit: 1,
            startDate: null,
            endDate: null,
            accountId,
            assetCode,
          });

        const statement = statementResponse.result?.[0]?.statements?.[0];
        const periodName = statementResponse.result?.[0]?.periodName || null;
        if (statement?.accountStatementId) {
          const pdfResponse =
            await helpers.injections.apiClient.getStatementPdf(
              statement.accountStatementId
            );
          return {
            ...pdfResponse,
            result: {
              statement: {
                ...statement,
                downloadFileName: computePdfName(statement, periodName),
              },
              pdf: pdfResponse.result,
            },
          };
        } else {
          throw new ApiError(
            404,
            ERROR_MESSAGES.STATEMENT_DOWNLOAD_MISSING,
            -1
          );
        }
      },
      result => result,
      {
        'x-account-id':
          accountId || helpers.getStoreState().user.defaultAccountId,
      }
    );
  }),

  // filters
  filters: null,
  setFilters: action((state, payload) => {
    state.filters = payload;
  }),
};
