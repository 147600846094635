import { Platform } from 'react-native';
export const resetZindexOptionally = (s) => {
    const result = [...s];
    if (Platform.OS === 'web') {
        result.push({
            /**
             * Note:
             * RN defaults zIndex as number (see their) types
             * Amd also defaults zIndex on all elements to 0
             *
             * Issue created:
             * This meant in web-app the floating wrapper for action buttons fell in place within its relative parents z-index
             * and thus was under the stacking order of the row below it
             *
             * With this optional platrform reaset, we are able to provide a different experience and fix for the web app
             */
            zIndex: 'initial',
        });
    }
    return result;
};
