import React, { FC, useCallback, useMemo, useState } from 'react';
import cx from 'classnames';
import { searchStringOrCurrency } from '../../utils';
import { FormHeader } from '../form-header';
import { SearchInput } from '../search-input';
import { DashboardHeader } from '../dashboard-header';
import { defaultFilterContentTemplate } from './templates';

export interface SearchFilterProps {
  search: string;
  filterBy?: unknown;
}

export interface SearchableModalProps {
  title?: string;
  value?: string;
  autoFocus?: boolean;
  defaultSearchFilter?: string[];
  onItemSelection?: (arg) => void;
  getItemValue?: (item: any) => any;
  onClose?: (close?: unknown) => void;
  values?: Record<string, string>[] | unknown[] | any[];
  headerTemplate?: (args?: unknown) => JSX.Element;
  contentTemplate?: (arg?: unknown) => JSX.Element;
  minHeight?: string;
}

export const SearchableModal: FC<SearchableModalProps> = ({
  value,
  values,
  onClose,
  autoFocus,
  headerTemplate,
  onItemSelection,
  defaultSearchFilter,
  title = 'Please select',
  contentTemplate = defaultFilterContentTemplate,
  minHeight,
}) => {
  /**
   * State
   */
  const [filterOption, setFilterOption] = useState<SearchFilterProps>({
    search: '',
    filterBy: null,
  });

  /**
   * Hooks
   */
  const filteredValues = useMemo(() => {
    return values?.filter(item =>
      searchStringOrCurrency(
        item,
        filterOption?.search,
        defaultSearchFilter ?? []
      )
    );
  }, [values, defaultSearchFilter, filterOption?.search]);

  const handleSearch = useCallback(
    arg => {
      setFilterOption({
        filterBy: filterOption?.filterBy,
        search: arg?.toLocaleLowerCase(),
      });
    },
    [filterOption?.filterBy]
  );

  /**
   * Methods
   */
  return (
    <div className={cx('flex flex-col', minHeight)}>
      {/* header  */}
      <DashboardHeader asStickyHeader headerPadding="rounded-lg">
        {/** nav header */}
        <FormHeader
          title={title}
          onBack={() => {
            if (onClose) onClose();
          }}
        />
        {/** search input */}
        <SearchInput
          autoFocus={autoFocus}
          onChange={({ target }) => handleSearch(target?.value)}
          handleClearInput={() =>
            setFilterOption({
              search: '',
              filterBy: filterOption?.filterBy,
            })
          }
          value={filterOption?.search}
        />
        {headerTemplate && headerTemplate()}
      </DashboardHeader>
      <section className="rounded-b max-h-485 border-t overflow-y-scroll">
        <React.Fragment>
          <div className="rounded">
            {filteredValues?.length ? (
              filteredValues?.map((item, itemIndex) => (
                <div
                  key={itemIndex}
                  className={cx(
                    'flex flex-row text-base font-bold truncate items-center cursor-pointer hover-bg-grey-brighter',
                    {
                      'border-b':
                        filteredValues.length > 0 &&
                        itemIndex + 1 !== filteredValues?.length,
                    }
                  )}
                  onClick={() => {
                    if (!onItemSelection) return;
                    onItemSelection(item);
                  }}
                >
                  {contentTemplate({
                    item,
                    value,
                    onItemSelection,
                  })}
                </div>
              ))
            ) : (
              <p className="text-grey-darker text-sm flex p-4 flex items-center justify-center">
                No matching values found
              </p>
            )}
          </div>
        </React.Fragment>
      </section>
    </div>
  );
};
