import { FC, useEffect, useState } from 'react';
import {
  useComputedConfig,
  useSystemConfig,
  getThemeForTenant,
  AppTenant,
} from 'common';
import { useLocation } from 'react-router-dom';
import { AppStore, DataStore } from '../store';

import { AppRoot } from './root';
import { APP_ROUTES } from '~/routes';

export const AppHydrator: FC = () => {
  /**
   * Store
   */
  const setCurrentViewName = DataStore.useStoreActions(
    s => s.setCurrentViewName
  );
  const accountIds = DataStore.useStoreState(s => s.portfolio.accountIds);
  const identity = DataStore.useStoreState(s => s.user.identity);
  const isAuthenticated = DataStore.useStoreState(s => s.user.isAuthenticated);

  const initPusher = AppStore.useStoreActions(_ => _.initPusher);
  const { pusherCluster, pusherKey } = useSystemConfig();
  const { envName } = useSystemConfig();

  /**
   * State
   */
  const [ready, setReady] = useState(false);

  /**
   * Hooks
   */
  const location = useLocation();
  const { tenant } = useComputedConfig();
  useEffect(() => {
    if (!tenant) {
      return;
    }
    const themeName = getThemeForTenant(tenant as AppTenant);
    import(`common/dist/theme/${themeName}/index.scss`)
      .then(() => {
        setReady(true);
      })
      .catch(error => {
        console.error(`Could not load theme for tenant - ${tenant}: ${error}`);
      });
  }, [tenant]);
  useEffect(() => {
    if (!isAuthenticated || !identity || accountIds.length <= 0) {
      return;
    }
    initPusher({
      pusherCluster,
      pusherKey,
      env: envName,
      accountIds,
      userId: identity.id,
    });
  }, [isAuthenticated, identity, accountIds, initPusher]);

  useEffect(() => {
    const viewName = location.pathname;
    if (viewName === APP_ROUTES.AUTH_MARKETS) {
      setCurrentViewName('markets-list');
      return;
    }
    setCurrentViewName('other');
  }, [location.pathname, setCurrentViewName]);

  /**
   * DOM
   */
  if (!ready) {
    return null;
  }
  return <AppRoot />;
};
