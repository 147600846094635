import { memo } from 'react';

type Props = {
  children: string;
};

export const FormError = memo(({ children: message }: Props) => {
  return (
    <div className="p-1">
      <span className="font-medium text-failure text-xs">{message}</span>
    </div>
  );
});
