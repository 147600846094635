import { createTheme } from 'react-native-themesheet';
const primaryColor = '#181428';
const neutralColor = '#676767';
export const colors = {
    // Background
    background: 'white',
    backgroundAlt: '#F8F8F8',
    // Surface
    surface: 'white',
    onSurface: neutralColor,
    onSurfaceDim: '#B8B8B8',
    onSurfaceInv: '#FFFFFF',
    onSurfaceEm: primaryColor,
    onSurfaceNegative: '#b33117',
    onSurfacePositive: '#229437',
    onTextInput: primaryColor,
    onTextInputDim: neutralColor,
    onTextInputEm: primaryColor,
    // Surface (primary)
    primarySurface: primaryColor,
    onPrimarySurface: 'white',
    // Surface (neutral)
    neutralSurface: '#ECECEC',
    onNeutralSurface: primaryColor,
    // Surface (info)
    infoSurface: '#EEF0FC',
    onInfoSurface: '#3F4B9C',
    // Surface (alt)
    surfaceActive: '#fafafa',
    surfaceAlt: '#fafafa',
    // Surface (Accent)
    surfaceAccent: '#58ffb2',
    surfaceAccentActive: '#defff0',
    // Border
    outline: '#ececec',
    infoSurfaceBorder: '#C5CBF4',
    accent: '#58ffb2',
    primary: '#181428',
    error: '#b33117',
    success: '#2a8a3a',
    textInfoDark: '#5262DF',
    // Utils
    transparent: 'transparent',
};
export const theme = {
    border: {
        primary: '#ececec',
        secondary: '#dbdbdb',
        tertiary: '#181428',
        info: '#5262df',
        success: '#229437',
        error: '#bb2102',
        warning: '#ffe65a',
    },
    text: {
        primary: '#181428',
        secondary: '#676767',
        tertiary: '#b8b8b8',
        info: '#5262df',
        success: '#229437',
        error: '#bb2102',
        warning: '#b79418',
        inverse: '#ffffff',
        placeholder: '#b8b8b8',
        disabled: '#dbdbdb',
    },
    button: {
        primary: {
            default: '#58ffb2',
            hover: '#46cc8e',
            pressed: '#3eb37d',
            focused: '#58ffb2',
        },
        secondary: {
            default: '#181428',
            hover: '#201b35',
            pressed: '#282142',
            focused: '#181428',
        },
        tertiary: {
            default: '#f8f8f8',
            hover: '#ececec',
            pressed: '#b8b8b8',
            focused: '#dbdbdb', // #ffffff
        },
        success: {
            default: '#147226',
            pressed: '#229437',
            hover: '#32B64B',
            focused: '#229437',
        },
        error: {
            default: '#FFC3B7',
            hover: '#FF9985',
            pressed: '#FF7053',
            focused: '#FFC3B7', // #bb2102
        },
    },
    spacing: {
        '1': 2,
        '2': 4,
        '3': 6,
        '4': 8,
        '5': 12,
        '6': 16,
        '7': 20,
        '8': 24,
        '9': 32,
        '10': 40,
        '11': 48,
        '12': 56,
        '13': 64,
        '14': 72,
        '15': 80,
        '16': 96,
        '17': 112,
    },
    radius: {
        sm: 4,
        md: 8,
        lg: 16,
        full: 9999,
    },
};
const sizes = {
    rad1: 4,
    rad2: 2,
    xxs: 4,
    xs: 8,
    s: 12,
    m: 16,
    l: 24,
    xl: 32,
};
// https://github.com/eightyfive/react-native-themesheet
// In a nutshell:
//   - createStyles({ color: 'colorName' }): "colorName" is type-checked,
//   - createStyles({ pr: 'm' }): "pr" is a shortcut (type-checked) for "paddingRight" and "m" ia size name (type-checked)
//   - createBox: see lib/box.tsx
export const { createBox, createStyles } = createTheme(colors, sizes);
