import { useCallback } from 'react';
import { DASHBOARD_SELECTED_DIALOG_TYPE } from '~/store/types';
import {
  EnrichedAccountDetailAsset,
  EnrichedAssetHolding,
  useSendForm,
} from 'common';
import { AppStore, DataStore } from '~/store';
import { apiClient } from '~/api/client';

export const useBeginWorkflow = () => {
  const getAccountDetail = DataStore.useStoreActions(
    _ => _.portfolio.getAccountDetail
  );
  const setDashboardSelectedAsset = AppStore.useStoreActions(
    _ => _.setDashboardSelectedAsset
  );
  const setSelectedCurrencyCode = AppStore.useStoreActions(
    _ => _.setSelectedCurrencyCode
  );
  const setDashboardSelectedDialogType = AppStore.useStoreActions(
    _ => _.setDashboardSelectedDialogType
  );

  const { setCurrencyCode: setSendCurrencyCode } = useSendForm({
    DataStore,
    apiClient,
  });

  return useCallback(
    async (
      dialogType: DASHBOARD_SELECTED_DIALOG_TYPE,
      assetOrCurrencyCode:
        | string
        | EnrichedAccountDetailAsset
        | EnrichedAssetHolding
        | null = null,
      accountId: string | null = null
    ) => {
      if (!accountId) {
        return;
      }
      // This is need to update the account info before opening the modal, otherwise Oops happens
      const { isSuccessful } = await getAccountDetail({
        accountId,
        isBackgroundXHR: true,
      });
      if (!isSuccessful) {
        return;
      }

      const currencyCode =
        typeof assetOrCurrencyCode === 'string'
          ? assetOrCurrencyCode
          : assetOrCurrencyCode?.currency.code;
      const asset =
        typeof assetOrCurrencyCode === 'string' ? null : assetOrCurrencyCode;

      const isTradeFlow =
        dialogType === 'workflow-buy' || dialogType === 'workflow-sell';
      const isSendFlow = dialogType === 'workflow-send';

      if (isTradeFlow) {
        setSelectedCurrencyCode(currencyCode ?? null);
      } else if (isSendFlow && currencyCode) {
        setSendCurrencyCode(currencyCode);
      } else if (typeof asset !== 'string') {
        setDashboardSelectedAsset(asset);
      }
      setDashboardSelectedDialogType(dialogType);
    },
    [
      setDashboardSelectedDialogType,
      setDashboardSelectedAsset,
      setSendCurrencyCode,
      getAccountDetail,
    ]
  );
};
