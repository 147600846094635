import { jsx as _jsx } from "react/jsx-runtime";
import { Pressable, StyleSheet, Text } from 'react-native';
import { theme } from '../../theme';
export const Button = ({ label, variant = 'primary', disabled, styles, onPress, }) => {
    let textColor = '';
    switch (variant) {
        case 'secondary':
        case 'success':
            textColor = theme.text.inverse;
            break;
        case 'error':
            textColor = theme.text.error;
            break;
        default:
            textColor = theme.text.primary;
    }
    return (_jsx(Pressable, Object.assign({ onPress: onPress, style: ({ pressed, hovered, focused }) => [
            $.pressable,
            $variants[variant],
            !disabled && focused && $focused[variant],
            !disabled && hovered && $hovered[variant],
            !disabled && pressed && $pressed[variant],
            disabled && $.disabled,
        ], disabled: disabled }, { children: _jsx(Text, Object.assign({ style: [$.text, styles, { color: textColor }] }, { children: label })) })));
};
const $ = StyleSheet.create({
    text: { fontWeight: 'bold' },
    pressable: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: theme.spacing['4'],
        borderRadius: theme.radius.sm,
        minHeight: 52, // TODO(Hadrien): not a design token ?
    },
    disabled: {
        opacity: 0.3,
    },
});
const $variants = StyleSheet.create({
    primary: { backgroundColor: theme.button.primary.default },
    secondary: { backgroundColor: theme.button.secondary.default },
    tertiary: { backgroundColor: theme.button.tertiary.default },
    success: { backgroundColor: theme.button.success.default },
    error: { backgroundColor: theme.button.error.default },
});
const $pressed = StyleSheet.create({
    primary: { backgroundColor: theme.button.primary.pressed },
    secondary: { backgroundColor: theme.button.secondary.pressed },
    tertiary: { backgroundColor: theme.button.tertiary.pressed },
    success: { backgroundColor: theme.button.success.pressed },
    error: { backgroundColor: theme.button.error.pressed },
});
const $hovered = StyleSheet.create({
    primary: { backgroundColor: theme.button.primary.hover },
    secondary: { backgroundColor: theme.button.secondary.hover },
    tertiary: { backgroundColor: theme.button.tertiary.hover },
    success: { backgroundColor: theme.button.success.hover },
    error: { backgroundColor: theme.button.error.hover },
});
const $focused = StyleSheet.create({
    primary: { backgroundColor: theme.button.primary.focused },
    secondary: { backgroundColor: theme.button.secondary.focused },
    tertiary: { backgroundColor: theme.button.tertiary.focused },
    success: { backgroundColor: theme.button.success.focused },
    error: { backgroundColor: theme.button.error.focused },
});
