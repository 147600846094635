import { FC } from 'react';
import { DataStore } from '~/store';
import { SettingsAccountLabels } from '../../components/settings/account-labels';
import { SettingsLayout, SettingsMenuIds } from './layout';

export const AccountLabels: FC = () => {
  /**
   * Store
   */
  const error = DataStore.useStoreState(s => s.error);
  const setError = DataStore.useStoreActions(s => s.setError);
  const accountLabelsErrors = DataStore.useStoreState(
    s => s.portfolio.accountLabelsErrors
  );
  const accountsByType = DataStore.useStoreState(
    s => s.portfolio.accountsByType
  );

  const updateAccountName = DataStore.useStoreActions(
    a => a.portfolio.updateAccountName
  );
  const setAccountLabelError = DataStore.useStoreActions(
    s => s.portfolio.setAccountLabelError
  );

  /**
   * Variables
   */
  const accountsType = (accountsByType || []).map(([type, accounts]) => ({
    type,
    accounts,
  }));

  /** Handlers */
  const handleOnAccountLabelChange = async (
    accountId: string,
    accountName: string
  ) => updateAccountName({ accountId, accountName });
  const handleClearError = (accountId: string) => {
    setError(null);
    setAccountLabelError({ accountId, error: null });
  };

  return (
    <SettingsLayout
      header="Account labels"
      hasPaddings={false}
      menuId={SettingsMenuIds.ACCOUNT_LABELS}
    >
      <SettingsAccountLabels
        error={error}
        clearError={handleClearError}
        accountLabelsErrors={accountLabelsErrors}
        accountsType={accountsType}
        onAccountLabelChange={handleOnAccountLabelChange}
      />
    </SettingsLayout>
  );
};
