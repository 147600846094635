const _calculateLineHeight = (fontSize, rawLineHeight) => {
    // TODO(Hadrien): Throw in Design System to avoid having calculations in the component,
    //    everything should be as pixels
    if (rawLineHeight.endsWith('%')) {
        return parseInt(((fontSize * parseFloat(rawLineHeight)) / 100).toString());
    }
    else {
        return fontSize;
    }
};
export const shorthandToFontProps = (fontShort) => {
    const fontProps = {};
    const props = fontShort.split(' ');
    // TODO(Hadrien): validate all the tokens when generating to ensure that we dont pass a value that does not match that type
    fontProps['fontWeight'] = props[0];
    const sizes = props[1].split('/');
    fontProps['fontSize'] = parseInt(sizes[0]);
    fontProps['lineHeight'] = _calculateLineHeight(parseInt(sizes[0]), sizes[1]);
    return fontProps;
};
