import { Platform } from 'react-native';
// TODO(Hadrien): re-add in theme
export const drop2 = boxShadow({
    elevation: 3,
    color: 'rgba(0, 0, 0, 0.06)',
    opacity: 1,
    radius: 20,
    spreadRadius: 0,
    offsetWidth: 0,
    offsetHeight: 6,
});
export function boxShadow({ color, opacity, radius, spreadRadius, offsetWidth, offsetHeight, elevation, }) {
    return Platform.select({
        ios: {
            shadowColor: color,
            shadowOpacity: opacity,
            shadowRadius: radius,
            shadowOffset: {
                width: offsetWidth,
                height: offsetHeight,
            },
        },
        android: {
            elevation,
        },
        web: {
            boxShadow: `${offsetWidth}px ${offsetHeight}px ${radius}px ${spreadRadius}px ${color}`,
        },
    });
}
